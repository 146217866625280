<template>
  <div class="custom-second-title">
    <img alt="" src="@/assets/images/title-logo.png">
    <div class="word-en">{{ currentLanguage === 1 ? zh : en }}</div>
    <!--    <div class="word-zh">{{ currentLanguage === 1 ? en : zh }}</div>-->
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    en: {
      type: String,
    },
    zh: {
      type: String,
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  }
}
</script>

<style lang="scss" scoped>
.custom-second-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 6.2rem;
    height: 3.6rem;
  }

  .word-en {
    font-size: 3.6rem;
    color: var(--default-color);
    font-weight: bold;
    margin-left: 1.8rem;
  }

  .word-zh {
    font-size: 1.8rem;
    color: #666666;
    margin-left: 1.5rem;
    margin-top: 1.3rem;
  }
}

@media screen and (max-width: 1024px) {
  .custom-second-title {
    .word-en {
      font-size: 5rem;
    }

    .word-zh {
      font-size: 3.4rem;
    }
  }
}

</style>
