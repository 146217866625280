<template>
  <div class="about-cont">
    <div class="about layout cx-fex">
      <div class="left">
        <div class="custom-title">
          <div :class="[fullpage.current === 2 ? ' animated fadeInRight' : 'animated fadeOutRight']" class="custom-title-bg"></div>
          <div :class="[fullpage.current === 2 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="wz">
            <!--            {{ sectionData.home_ename }}-->
            <!--            <span>{{ sectionData.home_name }}</span>-->
            {{ currentLanguage === 1 ? sectionData.home_name : sectionData.home_ename }}
          </div>

        </div>
        <div :class="[fullpage.current === 2 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="company-title ">{{ sectionData.title }}</div>
        <div :class="[fullpage.current === 2 ? ' animated fadeInLeft' : 'animated fadeOutLeft',isMobile?'zlc-t-hide3':'zlc-t-hide5']"
             class="common-desc desc-font-size-m ">
          {{ sectionData.introduction }}
        </div>
        <div class="cx-fex-r" style="margin-top: 3rem" v-if="!isMobile">
          <div :class="[fullpage.current === 2 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="custom-btn-more" @click="toAboutPage()">
            <img alt="" src="@/assets/images/arrow-right-double.png">
            {{ fixedTextData.more }}
          </div>
        </div>
      </div>
      <div :class="[fullpage.current === 2 ? ' animated fadeInRight' : 'animated fadeOutRight']" :style="{backgroundImage:`url(${sectionData.resource})`}"
           class="right"></div>
    </div>
    <div class="bottom">
      <div :class="[isMobile ? 'cx-fex-l' : 'cx-fex-a']" class=" cx-fex-itemsl">
        <div v-for="(item,index) in bottomList" :key="index" :style="{width: currentLanguage === 1 ? 'auto' : '20%'}" class="bottom-item desc-font-size-m">
          {{ item.desc3 || '' }}
          <span>
            <animate-number v-if="fullpage.current===2" :to="item.num" duration="1000" from="0"></animate-number>
            {{ item.add ? '+' : '' }}
          </span>
          {{ item.desc }}<br/>{{ item.desc2 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  props: {
    fullpage: {
      type: Object
    },
    sectionData: {
      type: Object
    }

  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData', 'isMobile']),
    introduction() {
      return this.sectionData.introduction.split('\n');
    },
    bottomList() {
      let list = [];
      if (this.currentLanguage === 1) {
        list = [
          {desc: '年', desc2: '创立于', num: this.fixedTextData.company_name1 * 1},
          {desc: '年', desc2: '持续创新和专业化经营', num: this.fixedTextData.company_name2 * 1},
          {desc: '亿元', desc2: '水务投资累计', desc3: '超', num: 200},
          {desc: '余项', desc2: '完成各类投资建设项目', num: this.fixedTextData.company_name3 * 1},
          {desc: '国家及地区', desc2: '业务范围', num: parseInt(this.fixedTextData.company_name4) * 1, add: true},
        ]
      } else if (this.currentLanguage === 2) {
        list = [
          {desc: '', desc2: 'Founded in', num: this.fixedTextData.company_name1 * 1},
          {desc: 'for many years', desc2: 'Continuous innovation and professional management', num: this.fixedTextData.company_name2 * 1},
          {desc: 'remainder', desc2: 'Complete various investment and construction projects', num: this.fixedTextData.company_name3 * 1},
          {desc: 'country and region', desc2: 'Business Scope', num: parseInt(this.fixedTextData.company_name4) * 1, add: true},
        ]
      }
      return list
    }
  },

  mounted() {


  },
  methods: {
    toAboutPage() {
      this.$router.push({
        path: '/about'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.about-cont {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  .left {
    width: 70rem;
    height: 60rem;
    padding: 17rem 16rem 0 24.5rem;

    .company-title {
      margin-top: 9rem;
      font-size: 3.6rem;
      color: #000000;
      margin-bottom: 2rem;
    }

    .company-desc {
      font-size: 1.6rem;
      color: #666666;
      line-height: 4.8rem;
      margin-top: 3.5rem;
    }
  }

  .right {
    //width: 63.6rem;
    //height: 75.8rem;
    width: 60rem;
    height: 55rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 17rem;
    margin-right: 16.4rem;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 160rem;
    height: 26.4rem;
    margin: 0 auto;
    background-image: url("@/assets/images/index-about-bottom-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;

    .bottom-item {
      padding-top: 17rem;
      padding-bottom: 2rem;
      font-size: 1.6rem;
      color: #666666;

      span {
        color: var(--default-color);
        font-size: 3.6rem;
        //font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about-cont {
    justify-content: flex-start;

    .about {
      flex-direction: column-reverse;
      justify-content: flex-end !important;
      align-items: center;

      .left {
        padding: 5rem 10rem 0;
        width: calc(100% - 20rem);
        height: auto;
        .company-title {
          margin-top: 4rem;
        }
      }

      .right {
        width: 50rem;
        height: 55rem;
        margin: 20rem auto 0;
      }
    }

    .bottom {
      width: calc(100% - 10rem);
      padding: 5rem;
      height: 40rem;


      .bottom-item {
        width: calc(50% - 5rem) !important;
        padding-top: 0;
        padding-left: 5rem;
        font-size: 1.8rem;
        color: #666666;

        span {
          color: var(--default-color);
          font-size: 3.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
