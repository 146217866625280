<template>
  <div class="__section-main__">
    <div class="download-center">
      <common-title :en="'DOWNLOAD CENTER'" :zh="'下载中心'"></common-title>
      <div class="container">
        <div class="cx-fex-c">
          <div class="search-cont animated fadeInDown" style="margin: 0 auto 5rem">
            <input :placeholder="fixedTextData.search_name" type="text" v-model="title">
            <div class="search-btn" @click="getDownloadList()">{{ fixedTextData.search }}</div>
          </div>
        </div>
      </div>

      <div class="container">
        <template v-if="list.length > 0">
          <div class="list-cont" v-for="(listItem,listIndex) in list" :key="listIndex">
            <div class="layout cx-fex-l">
              <div class="list-item reveal-top" v-for="(item,index) in listItem" :key="index">
                <div class="item-main">
                  <div class="image-cont" :style="{backgroundImage:`url(${$tool.getImage(item.resource)})`}"></div>
                  <div class="info-cont">
                    <a :href="$tool.getImage(item.resource_files)" target="_blank">{{ currentLanguage === 1 ? '预览' : 'preview' }}</a>
                    <a @click="downBlobFile(item.resource_files,item.name)">{{ currentLanguage === 1 ? '下载' : 'download' }}</a>
                  </div>
                </div>
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
            <div class="list-cont-bottom reveal-top"></div>
          </div>
        </template>

        <div v-if="list.length === 0" class="list-no-data">暂无数据</div>

      </div>
    </div>


  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import {getDownLoadCateList, getDownLoadList} from "@/request/api";
import {mapState} from "vuex";
import scrollReveal from "scrollreveal";

export default {
  components: {CommonTitle},
  data() {
    return {
      classifyList: [],
      currentIndex: 0,
      title: '',
      list: [],
      scrollReveal: scrollReveal(),
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData', 'isMobile']),
  },
  created() {
    this.getDownloadList();
  },
  methods: {
    downBlobFile(url, fileName) {
      const x = new window.XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = fileName;
        a.style.display = "none";
        document.body.append(a);
        a.click();
      };
      x.send();
    },
    async getDownloadList() {
      let res = await getDownLoadList({language_id: this.currentLanguage, name: this.title});
      this.list = this.setTwoDimensionalArray(res.data);
      console.log(this.list)
      this.$nextTick(() => {
        this.setScrollRevealClass()
      })
    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: '4rem',
        // 其他可用的动画效果
        // opacity: 0.001,
        // easing: 'linear',
        // scale: 0.9,

      })
    },
    setTwoDimensionalArray(list) {
      const listResult = [];  // 最终返回的二维数组
      let spliceNum = this.isMobile ? 2 : 4;
      for (let i = 0; i < Math.ceil((list.length / spliceNum)); i++) {
        listResult[i] = [];
        for (let j = 0; j < spliceNum; j++) {
          // 如果是最后一个板块
          if (i === (Math.ceil((list.length / spliceNum)) - 1)) {
            if (Math.ceil((list.length % spliceNum)) !== 0) {
              // 只有最后一个板块的数据在余数以内的才赋值
              if (j < Math.ceil((list.length % spliceNum))) {
                listResult[i][j] = list[i * spliceNum + j];
              }
            } else {
              // 如果刚好整整一个板块，则全部附上值
              listResult[i][j] = list[i * spliceNum + j];
            }
          } else {
            listResult[i][j] = list[i * spliceNum + j];
          }
        }
      }
      return listResult;
    }
  }
}
</script>

<style lang="scss" scoped>
.download-center {
  .list-cont {
    width: calc(100% - 6rem);
    padding: 0 3rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .list-item {
      width: 20rem;
      display: flex;
      flex-direction: column;
      margin-right: 11rem;
      &:last-child{
        margin-right: 0;
      }

      .item-main {
        box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.08);

        .image-cont {
          width: 20rem;
          height: 25rem;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

        }

        .info-cont {
          display: flex;
          height: 3.5rem;
          align-items: center;

          & > a {
            font-size: 1.6rem;
            color: var(--default-color);
            width: 50%;
            text-align: center;
            line-height: 2rem;
            cursor: pointer;
          }

          & > a:nth-of-type(1) {
            border-right: .1rem solid #CCCCCC;
          }
        }
      }

      .title {
        font-size: 1.8rem;
        text-align: center;
        margin-top: 1rem;
      }
    }

    .list-cont-bottom {
      width: 100%;
      height: 3rem;
      background-color: #ffffff;
      margin-top: 2rem;
      box-shadow: -1rem 1rem 3rem -1rem rgba(0, 0, 0, 0.2);
      margin-bottom: 7rem;

    }
  }
}


@media screen and (max-width: 1024px) {
  .download-center {
    .list-cont {
      .list-item {
        width: 46%;
        display: flex;
        flex-direction: column;
        margin-right: 8%;
        &:last-child{
          margin-right: 0;
        }

        .item-main {
          box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.08);

          .image-cont {
            width: 100%;
            height: 50rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

          }

          .info-cont {
            display: flex;
            height: 3.5rem;
            align-items: center;

            & > a {
              font-size: 1.6rem;
              color: var(--default-color);
              width: 50%;
              text-align: center;
              line-height: 2rem;
              cursor: pointer;
            }

            & > a:nth-of-type(1) {
              border-right: .1rem solid #CCCCCC;
            }
          }
        }

        .title {
          font-size: 1.8rem;
          text-align: center;
          margin-top: 1rem;
        }
      }

      .list-cont-bottom {
        width: 100%;
        height: 3rem;
        background-color: #ffffff;
        margin-top: 2rem;
        box-shadow: -1rem 1rem 3rem -1rem rgba(0, 0, 0, 0.2);
        margin-bottom: 7rem;

      }
    }
  }
}
</style>
