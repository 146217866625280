import Vue from 'vue'
import VueRouter from "vue-router";


const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch((err) => err)
}
VueRouter.prototype.replace = function(location) {
    return originalReplace.call(this, location).catch((err) => err)
}

import HomeView from "@/views/home/index";
import newsList from "@/views/news/newsList";
import newsDetail from "@/views/news/newsDetail";
import mainBusiness from "@/views/mainBusiness/index";
import mainBusinessDetail from "@/views/mainBusiness/details/index";
import about from "@/views/about/index";
import productList from "@/views/product/productList";
import classicCases from "@/views/classicCases";
import talentCenter from "@/views/talentCenter";
import parkDetail from "@/views/about/parkDetail";
import downloadCenter from "@/views/downloadCenter";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/about',
        component: about
    },
    {
        path: '/parkDetail',
        component: parkDetail
    },
    {
        path: '/productList',
        component: productList
    },
    {
        path: '/newsList',
        component: newsList
    },
    {
        path: '/newsDetail',
        component: newsDetail
    },
    {
        path: '/mainBusiness',
        component: mainBusiness
    },
    {
        path: '/mainBusiness/detail',
        component: mainBusinessDetail
    },
    {
        path: '/talentCenter',
        component: talentCenter
    },
    {
        path: '/classicCases',
        component: classicCases
    },
    {
        path: '/downloadCenter',
        component: downloadCenter
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior: () => ({y: 0}), //滚动到顶端
})

export default router
