import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/css/common.scss"
import "@/assets/css/zlc.scss"

import "swiper/dist/css/swiper.min.css"

import '@/filters/filters'

// import animated from "animate.css"
// Vue.use(animated)
import "animate.css"

import VueAnimateNumber from 'vue-animate-number'

Vue.use(VueAnimateNumber)

import tool from "@/utils/tool.js"

Vue.prototype.$tool = tool;


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
