<template>
  <div class="main-cont">
    <div class="left">
      <div class="title-cont animated fadeInDown">
        <div class="title">
          <div class="cx-fex-l cx-fex-itemsc">
            <img alt="" src="@/assets/images/title-logo.png">
            <div style="flex: 1">{{ currentLanguage === 1 ? leftData.cate_name : leftData.e_name }}</div>

          </div>
        </div>
        <!--        <div class="subtitle">{{ currentLanguage === 1 ? leftData.e_name : leftData.cate_name }}</div>-->
      </div>
      <div v-if="$route.path==='/productList'" class="desc animated fadeInUp desc-font-size-m" v-html="leftData.introduction"></div>
      <div class="desc animated fadeInUp desc-font-size-m" v-else>
        <div style="font-size: 1.6rem;min-height: 1.6rem" v-for="(item,index) in leftData.introduction.split('\n')" :key="index">
          {{ item }}
        </div>
      </div>

    </div>

    <div class="right animated fadeInRight">
      <div class="swiper-container list-swiper" style="height: 100%">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in _list" :key="index" class="swiper-slide">
            <div class="right-item">
              <div v-for="(item1,index) in item" :key="index" class="item-cont"
                   @click="showPopup(item1)">
                <div class="image-cont" :style="{backgroundImage:`url(${$tool.getImage(item1.resource)})`}"></div>
                <div class="bottom-cont">
                  <div class="title zlc-t-hide">{{ item1.name }}</div>
                  <div class="cx-fex cx-fex-itemsc" style="margin-top: 1.5rem">
                    <!--                    <div class="size">工程规模：{{item1.size}}</div>-->
                    <div class="size">{{ item1.scale }}</div>
                    <div class="btn">
                      <span>{{ fixedTextData.more }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination list-swiper-pagination"></div>
      </div>
    </div>

    <custom-popup v-model="popupVisible" :current-case="currentCase"></custom-popup>

  </div>
</template>

<script>
import Swiper from "swiper";
import {mapState} from "vuex";
import CustomPopup from "@/components/customPopup";

export default {
  components: {CustomPopup},
  props: {
    list: {
      type: Array
    },
    leftData: {
      type: Object
    }
  },
  data() {
    return {
      popupVisible: false,
      currentCase: null,
      listSwiper: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData']),
    _list() {
      let list = this.list;
      list = this.setTwoDimensionalArray(list);
      return list;
    }
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(e) {
        this.$nextTick(() => {
          new Swiper(".list-swiper", {
            // loop: true,
            pagination: {
              el: ".list-swiper-pagination",
              clickable: true,
            },
          });

        })
      }
    },
  },
  methods: {
    showPopup(item) {
      this.currentCase = item;
      this.popupVisible = true;
    },
    setTwoDimensionalArray(list) {
      const listResult = [];  // 最终返回的二维数组
      for (let i = 0; i < Math.ceil((list.length / 4)); i++) {
        listResult[i] = [];
        for (let j = 0; j < 4; j++) {
          // 如果是最后一个板块
          if (i === (Math.ceil((list.length / 4)) - 1)) {
            if (Math.ceil((list.length % 4)) !== 0) {
              // 只有最后一个板块的数据在余数以内的才赋值
              if (j < Math.ceil((list.length % 4))) {
                listResult[i][j] = list[i * 4 + j];
              }
            } else {
              // 如果刚好整整一个板块，则全部附上值
              listResult[i][j] = list[i * 4 + j];
            }
          } else {
            listResult[i][j] = list[i * 4 + j];
          }
        }
      }
      return listResult;
    }
  }
}
</script>

<style lang="scss" scoped>
.main-cont {
  display: flex;
  justify-content: center;
  padding: 12rem 19rem;
  overflow: hidden;

  .left {
    width: 56.4rem;
    padding-right: 9.5rem;

    .title-cont {
      width: 100%;

      .title {
        width: 100%;
        height: 7.8rem;
        padding-bottom: 1.3rem;
        background-image: url("@/assets/images/title-bg-logo.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        display: flex;
        align-items: flex-end;
        font-size: 4rem;
        color: var(--default-color);

        img {
          width: 4.6rem;
          height: 2.6rem;
          margin-right: 2rem;
        }
      }

      .subtitle {
        font-size: 2.6rem;
        color: #999999;
      }
    }

    .desc {
      margin-top: 3rem;
      font-size: 1.6rem;
      line-height: 2;
      color: #666666;
    }
  }

  .right {
    width: 88.6rem;
    height: 73rem;

    .right-item {
      width: 88.6rem;
      height: 68.2rem;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .item-cont {
        width: 42.1rem;
        height: 32.1rem;
        margin: 1rem;
        position: relative;
        cursor: pointer;
        transition: all 0.5s ease;
        overflow: hidden;

        .image-cont{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          transition: all 0.5s ease;
        }


        .bottom-cont {
          width: calc(100% - 4rem);
          padding: 5rem 2rem 3rem;
          background-image: linear-gradient(to bottom, transparent 10%, rgba(9, 103, 209, .8));
          //background-color: rgba(9,103,209,.5);
          //background: transparent;
          position: absolute;
          left: 0;
          bottom: 0;
          color: #ffffff;

          .title {
            line-height: 3rem;
            border-bottom: .1rem solid #ffffff;
            font-size: 1.8rem;
          }

          .size {
            font-size: 1.4rem;
          }

          .btn {
            width: 6.7rem;
            height: 2.1rem;
            background-image: url("@/assets/images/product-more-bg.png");
            color: var(--default-color);
            font-size: 1.0rem;
            line-height: 2.1rem;
            cursor: pointer;

            span {
              display: block;
              transform: scale(0.8);
            }
          }
        }

        &:hover {
         .image-cont{
           transform: scale(1.2);
         }
        }
      }
    }
  }
}

.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;

  .popup-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup-content {
    width: 92rem;
    overflow: hidden;
    border-radius: 2rem;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .image-cont {
      width: 100%;
      height: 34.5rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      color: #ffffff;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 3rem;
        margin-left: 2rem;
      }
    }

    .word-cont {
      padding: 5rem 8rem 5rem;

      .title {
        font-size: 2rem;
        color: var(--default-color);
      }

      .time {
        font-size: 1.6rem;
        color: #666666;
        margin: 1rem 0 1rem;
        padding-bottom: 1.5rem;
        border-bottom: .1rem solid #f1f1f1;
      }

      .size,
      .desc {
        font-size: 1.6rem;
        line-height: 3.8rem;
        color: #666666;
      }
    }
  }

}

@media screen and (max-width: 1024px) {
  .main-cont {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0 0;

    .left {
      width: calc(100% - 10rem);
      padding: 5rem;
    }

    .right {
      height: 76rem;

      .right-item {
        .item-cont {
          .bottom-cont {
            .btn {
              width: 10rem;
              height: 3rem;
              line-height: 3rem;
              background-size: cover;
            }
          }
        }
      }
    }
  }

  .custom-popup {


    .popup-content {
      width: 90%;


      .image-cont {
        width: 100%;
        height: 34.5rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        color: #ffffff;
        font-size: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 3rem;
          margin-left: 2rem;
        }
      }

      .word-cont {
        .title {
          font-size: 4rem;
        }

        .time {
          font-size: 3rem;
          color: #666666;
          margin: 1rem 0 1rem;
          padding-bottom: 1.5rem;
          border-bottom: .1rem solid #f1f1f1;
        }

        .size,
        .desc {
          font-size: 3rem;
          line-height: 1.5;
          color: #666666;
        }
      }
    }

  }
}
</style>
