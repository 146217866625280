<template>
  <div :class="[fullpage.current === 1 ? 'animated zoomIn' : 'animated zoomOut']" class="section-1">
    <div style="height: 10rem"></div>
    <div class="swiper-container section1-swiper">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in sectionData" :key="index" class="swiper-slide">
          <div :style="{backgroundImage:`url(${isMobile ? item.images : item.resource})`}" class="banner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {mapState} from "vuex";

export default {
  props: {
    fullpage: {
      type: Object
    },
    sectionData: {
      type: Array
    }
  },
  computed: {
    ...mapState(['isMobile']),
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper('.section1-swiper', {})
    })

  }
}
</script>

<style lang="scss" scoped>
.section-1 {
  width: 100%;
  height: 100vh;

  .swiper-container {
    width: 100%;
    height: calc(100% - 10rem);

    .swiper-wrapper {
      width: 100%;
    }

    .banner {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>
