import request from "@/utils/request";

/**
 * 首页数据
 * */
export const getHome = (data) => {
    return request.post(`/api/home`, data);
}
export const getLanguageList = (data) => {
    return request.post(`/api/language`, data);
}
export const getNavigationList = (data) => {
    return request.post(`/api/navigation`, data);
}
export const getFooterData = (data) => {
    return request.post(`/api/bottom`, data);
}
export const getLanguageData = (data) => {
    return request.post(`/api/zh_en`, data);
}


/**
 * 关于鹏鹞
 * */
//关于鹏鹞、企业简介
export const getCompany = (data) => {
    return request.post(`/api/company`, data);
}
//发展历程
export const getCompanyHistory = (data) => {
    return request.post(`/api/history`, data);
}
//资质荣誉
export const getCompanyHonors = (data) => {
    return request.post(`/api/honors`, data);
}
//企业文化
export const getCompanyCulture = (data) => {
    return request.post(`/api/culture`, data);
}
//鹏鹞十大好习惯
export const getCompanyHabitList = (data) => {
    return request.post(`/api/habitList`, data);
}
//制造园区
export const getCompanyPark = (data) => {
    return request.post(`/api/parkList`, data);
}
//制造园区入驻企业
export const getSettledEnterpriseList = (data) => {
    return request.post(`/api/settledEnterpriseList`, data);
}

/**
 * 主营业务
 * */
export const getBusinessList = (data) => {
    return request.post(`/api/business`, data);
}
export const getBusinessCaseList = (data) => {
    return request.post(`/api/businessCateCase`, data);
}

/**
 * 技术产品
 * */

export const getTechnologyProductList= (data) => {
    return request.post(`/api/technologyProduct`, data);
}
export const getProductCateList = (data) => {
    return request.post(`/api/technologyProductCate`, data);
}
export const getProductCaseList = (data) => {
    return request.post(`/api/technologyProductCateCase`, data);
}
/**
 * 经典案例
 * */
export const getClassicCasesCateList = (data) => {
    return request.post(`/api/classics`, data);
}
export const getClassicCasesCaseList = (data) => {
    return request.post(`/api/classicsCateCase`, data);
}


/**
 * 企业动态
 * */
export const getNewsClassifyList = (data) => {
    return request.post(`/api/articlesCateList`, data);
}
export const getNewsList = (data) => {
    return request.post(`/api/articlesList`, data);
}
export const getNewsDetail = (data) => {
    return request.post(`/api/articlesFind`, data);
}


/**
 * 人才管理
 * */
export const getTalentCateList = (data) => {
    return request.post(`/api/talent`, data);
}

export const getTalentCaseList = (data) => {
    return request.post(`/api/talentCateCase`, data);
}

export const getCooperateList = (data) => {
    return request.post(`/api/cooperateList`, data);
}


/**
 * 下载管理
 * */
export const getDownLoadCateList = (data) => {
    return request.post(`/api/downloadsCate`, data);
}
export const getDownLoadList = (data) => {
    return request.post(`/api/downloads`, data);
}
