<template>
  <div class="partner-model">
    <!--    <common-title en="PARTNER MODEL" zh="合伙人模式"></common-title>-->
    <common-title :en="cateData.children[0].e_name" :zh="cateData.children[0].name"></common-title>
    <div class="partner-model-desc desc-font-size-m reveal-top">
      {{ cateData.children[0].introduction }}
    </div>

    <div class="area-cont">
      <div class="partner-model-title reveal-top">合作领域</div>
      <div class="area-list">
        <div v-for="(item,index) in areaList" :key="index" class="list-item  reveal-top">
          <i :class="[item.icon]" class="cx-icon"></i>
          <div class="title" v-text="item.title"></div>
        </div>
      </div>
    </div>

    <div class="madel-cont">
      <div class="partner-model-title  reveal-top">合作形式</div>
      <div class="model-list">
        <div v-for="(item,index) in modelList" :key="index" class="list-item  reveal-top">
          <div :style="{backgroundImage:`url(${$tool.getImage(item.background_image)})`}" class="image-cont"></div>
          <div class="word-cont">
            <div class="title">{{ item.name }}</div>
            <div class="desc desc-font-size-m">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import scrollReveal from "scrollreveal";
import {getCooperateList} from "@/request/api";
import {mapState} from "vuex";

export default {
  components: {CommonTitle},
  props: {
    cateData: {
      type: Object
    }
  },
  data() {
    return {
      areaList: [
        {title: '环保水处理项目\n（工艺、装配）', icon: 'cx-wushuichuli'},
        {title: '固废处理项目', icon: 'cx-icon_gufeiguanli'},
        {title: '高科技、节能环保、新能源\n可持续再生能源等产业项目', icon: 'cx-zaisheng012'},
      ],
      modelList: [],

      scrollReveal: scrollReveal()
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  },
  created() {
    this.getCooperateList();
  },
  mounted() {

  },
  methods: {
    async getCooperateList() {
      let res = await getCooperateList({language_id: this.currentLanguage});
      this.modelList = res.data;
      this.$nextTick(() => {
        this.setScrollRevealClass();
      })
    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: '4rem',
        // 其他可用的动画效果
        // opacity: 0.001,
        // easing: 'linear',
        // scale: 0.9,

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.partner-model {
  .partner-model-desc {
    width: 120rem;
    margin: 0 auto;
    color: #666666;
    font-size: 1.6rem;
    line-height: 4.8rem;
  }

  .partner-model-title {
    font-size: 3.6rem;
    color: #333333;
    line-height: 1;
    text-align: center;
  }

  .area-cont {
    width: 180rem;
    margin: 7rem auto 0;
    background-image: url("@/assets/images/partner-model-area-bg.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: auto;

    .area-list {
      width: 120rem;
      display: flex;
      justify-content: space-between;
      margin: 9rem auto 0;
      padding-bottom: 10rem;

      .list-item {
        width: 24rem;
        height: 16rem;
        padding: 4rem 6.5rem 0;
        display: flex;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        border: .1rem solid #DEDEDE;
        background-color: #ffffff;

        i {
          font-size: 6rem;
          color: var(--default-color);
        }

        .title {
          font-size: 1.6rem;
          color: #666666;
          margin-top: 2.5rem;
          line-height: 1.5;
          text-align: center;
        }
      }
    }
  }

  .madel-cont {
    padding: 5rem 0 10rem;

    .model-list {
      width: 143rem;
      margin: 15rem auto 0;
      display: flex;
      justify-content: space-between;

      .list-item {
        width: 34.2rem;
        box-shadow: 0 0 5rem 0 rgba(0, 0, 0, 0.1);

        .image-cont {
          width: 100%;
          height: 23.7rem;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .word-cont {
          padding: 1.5rem;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 1.8rem;
            margin-top: 2rem;
          }

          .desc {
            margin-top: 2rem;
            font-size: 1.6rem;
            line-height: 2;
            color: #666666;
          }

        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .partner-model {
    width: 90%;
    margin: 0 auto;

    .partner-model-desc {
      width: 100%;
    }

    .partner-model-title {
      font-size: 6rem;
    }

    .area-cont {
      width: 100%;

      .area-list {
        flex-direction: column;
        width: 100%;

        .list-item {
          margin: 2rem auto;
          width: calc(100% - 13rem);
          height: 24rem;

          .title {
            font-size: 3rem;
          }
        }
      }
    }

    .madel-cont {
      padding: 0;
      margin: 0 auto;

      .model-list {
        width: 100%;
        flex-direction: column;
        padding: 0;

        .list-item {
          width: 100%;
          margin: 3rem auto;

          .word-cont {
            padding: 1.5rem;
            display: flex;
            flex-direction: column;

            .title {
              font-size: 3.4rem;
            }

          }
        }
      }
    }
  }
}
</style>
