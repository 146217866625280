<template>
  <div v-if="value" class="custom-popup">
    <div class="popup-bg" @click="$emit('input',false)"></div>
    <div class="popup-content animated fadeInDown">
      <div class="swiper-container popup-swiper" style="height: 34.5rem;width: 100%">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in (currentCase.images || [])" :key="index" class="swiper-slide">
            <div :style="{backgroundImage: `url(${$tool.getImage(item.url)})`,backgroundSize: popupType==='park'?'contain':'cover'}" class="image-cont">
              <!--              {{ currentCase.name }} <i class="cx-icon cx-dizhi"></i>-->
            </div>
          </div>
        </div>
        <div class="swiper-pagination popup-swiper-pagination"></div>
      </div>

      <div class="word-cont">
        <div class="title">{{ currentCase.name }}</div>
        <!--        <div class="time" v-if="popupType==='park'">发表日期：{{ currentCase.create_time }}</div>-->
        <div v-if="popupType==='product'" class="time">{{ currentCase.scale }}</div>

        <div class="custom-scroll-cont" style="height: 20rem">
          <template v-if="popupType==='product'">
            <div class="desc" v-for="(item,index) in currentCase.description.split('\n')" :key="index">
              {{ item }}
            </div>
          </template>

          <div v-if="popupType==='park'" class="desc" v-html="currentCase.description"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  name: "customPopup",
  props: {
    currentCase: {
      type: [Object, null],
    },
    popupType: {
      type: String,
      default: 'product'
    },
    value: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    currentCase: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          new Swiper(".popup-swiper", {
            // loop: true,
            pagination: {
              el: ".popup-swiper-pagination",
            },
          });
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999 !important;

  .popup-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup-content {
    width: 92rem;
    overflow: hidden;
    border-radius: 2rem;
    background-color: #ffffff;
    position: absolute;
    top: 10rem;
    left: 50%;
    margin-left: -46rem;
    //transform: translate(-50%, -50%);

    .image-cont {
      width: 100%;
      height: 34.5rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      color: #ffffff;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 3rem;
        margin-left: 2rem;
      }
    }

    .word-cont {
      padding: 5rem 8rem 5rem;

      .title {
        font-size: 1.8rem;
        color: var(--default-color);
      }

      .time {
        font-size: 1.6rem;
        color: #666666;
        margin: 1rem 0 1rem;
        padding-bottom: 1.5rem;
        border-bottom: .1rem solid #f1f1f1;
      }

      .size,
      .desc {
        font-size: 1.6rem;
        line-height: 2;
        color: #666666;
        min-height: 1.6rem;
      }
    }
  }

}


@media screen and (max-width: 1024px) {
  .custom-popup {
    .popup-content {
      top: 50rem;
    }
  }
}
</style>
