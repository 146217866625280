<template>
  <div ref="fullPage" class="home-index" v-if="homeData">
    <web-header :show-header="false" :has-header-bg="true"></web-header>
    <div
        ref="fullPageContainer"
        class="home-index-container"
        @DOMMouseScroll="mouseWheelHandle"
        @mousewheel="mouseWheelHandle"
        @touchend="handleTouchend"
        @touchmove="handleTouchmove"
        @touchstart="handleTouchstart"
    >
      <div class="section section-1">
        <section1 :fullpage="fullpage" :sectionData="homeData.ad"></section1>
      </div>
      <div class="section section-2">
        <section2 :fullpage="fullpage" :sectionData="homeData.company"></section2>
      </div>
      <div class="section section-3">
        <section3 :fullpage="fullpage" :sectionData="homeData.business"></section3>
      </div>
      <div class="section section-4">
        <section4 :fullpage="fullpage" :sectionData="homeData.service"></section4>
      </div>
      <div class="section section-5">
        <section5 :fullpage="fullpage" :sectionData="bottomData"></section5>
      </div>
    </div>

  </div>
</template>

<script>
import section3 from "@/views/home/section3";
import Section4 from "@/views/home/section4";
import Section5 from "@/views/home/section5";
import Section2 from "@/views/home/section2";
import WebHeader from "@/components/web-header";

import {getHome, getFooterData} from "@/request/api";
import {mapState} from "vuex";
import Section1 from "@/views/home/section1";

export default {
  components: {Section1, WebHeader, Section2, Section5, Section4, section3},
  data() {
    return {
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0 // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      startTime: undefined, // 记录触摸开始的时间
      startX: undefined, // 记录触摸开始的X坐标，本次主要实现的是上下滑动，所以当前坐标不做强制要求
      startY: undefined, // 记录触摸开始的Y坐标

      homeData: null,
      bottomData: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  },
  created() {
    this.getHomeData();
    this.getFooterData();
  },
  methods: {
    async getFooterData() {
      let res = await getFooterData({language_id: this.currentLanguage});
      this.bottomData = res.data.bottom;
    },
    async getHomeData() {
      let res = await getHome({language_id: this.currentLanguage});
      this.homeData = res.data;
    },
    // 往下切换
    next() {
      let len = 5; // 页面的个数
      if (this.fullpage.current + 1 <= len) { // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    // 往上切换
    pre() {
      if (this.fullpage.current - 1 > 0) { // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1;// 页面+1
        this.move(this.fullpage.current);// 执行切换
      }
    },
    // 滚动事件
    move(index) {
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {  //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    // 执行滚动
    directToMove(index) {
      let height = this.$refs["fullPage"].clientHeight; //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"]; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    // 监听鼠标监听
    mouseWheelHandle(event) {
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) { // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
    // 清除触摸事件
    handleTouchmove(event) {
      event.preventDefault()
    },
    //手指按下屏幕
    handleTouchstart(event) {
      this.startTime = Date.now()
      this.startX = event.changedTouches[0].clientX
      this.startY = event.changedTouches[0].clientY
    },
    //手指离开屏幕
    handleTouchend(event) {
      const endTime = Date.now()
      const endX = event.changedTouches[0].clientX
      const endY = event.changedTouches[0].clientY
      //判断按下的时长
      if (endTime - this.startTime > 2000) {
        return
      }
      //滑动的方向
      let direction = "";
      //先判断用户滑动的距离，是否合法，合法:判断滑动的方向 注意 距离要加上绝对值
      if (Math.abs(endY - this.startY) > 50) {
        //滑动方向
        direction = endY - this.startY > 0 ? "down" : "up"
      } else {
        return
      }
      //用户做了合法的滑动操作
      // console.log('方向'+direction)
      if (direction === 'up') {
        this.next();
      }
      if (direction === 'down') {
        this.pre();
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.home-index {
  height: 100vh; //一定要设置，保证占满
  overflow: hidden; //一定要设置，多余的先隐藏
  .home-index-container {
    width: 100%;
    height: 100vh;
    transition: all linear 0.5s;
  }

  .section {
    width: 100%;
    height: 100vh;
  }

  //.section-1 {
  //  background-image: url("@/assets/images/index-banner.png");
  //  background-position: left;
  //  background-size: cover;
  //  background-repeat: no-repeat;
  //}


}

</style>
