<template>
  <div class="__section-main__">
    <template v-if="navList.length > 0">
      <custom-nav v-model="currentIndex" :list="navList"></custom-nav>
      <custom-second-nav v-model="currentSecondIndex" :list="navList[currentIndex].children"></custom-second-nav>
      <product-item v-if="showProductItem" :leftData="navList[currentIndex].children[currentSecondIndex]" :list="list"></product-item>
    </template>


  </div>
</template>

<script>
import CustomNav from "@/components/customNav";
import ProductItem from "@/components/productItem";
import CustomSecondNav from "@/components/customSecondNav";
import {getProductCaseList, getProductCateList, getTechnologyProductList} from "@/request/api";
import {mapState} from "vuex";

export default {
  components: {ProductItem, CustomNav,CustomSecondNav},
  data() {
    return {
      currentIndex: 0,
      currentSecondIndex: 0,
      navList: [],
      showProductItem: true,
      list: []
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
  },
  created() {
    this.getTechnologyProductList();
    // this.getProductCateList();
  },
  mounted() {

  },
  watch: {
    "$route.query.firstIndex": {
      immediate: true,
      handler(e) {
        this.currentIndex = e ? e * 1 : 0;
      }
    },
    '$route.query.secondIndex': {
      immediate: true,
      handler(e) {
        this.currentSecondIndex = e? e * 1 : 0;
      }
    },
    currentIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: e,
          secondIndex: 0
        }
      })
      this.currentSecondIndex = 0;
      this.getProductCaseList(this.navList[this.currentIndex].children[0].id);
    },
    currentSecondIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: this.currentIndex,
          secondIndex: e
        }
      })
      this.getProductCaseList(this.navList[this.currentIndex].children[e].id);
    },

  },
  methods: {
    async getTechnologyProductList() {
      let res = await getTechnologyProductList({language_id: this.currentLanguage});
      let navList = res.data;
      navList = navList.map(item => {
        return {
          ...item,
          title: item.name,
          icon: item.image,
          children: [],
        }
      })
      this.navList = navList;

      for (let i = 0; i < navList.length; i++) {
        await this.getProductCateList(navList[i].id,i);
      }
      await this.getProductCaseList(this.navList[this.currentIndex].children[this.currentSecondIndex].id);
    },
    async getProductCateList(technology_product_id,index) {
      let res = await getProductCateList({language_id: this.currentLanguage,technology_product_id});
      this.navList[index].children = res.data;

    },
    async getProductCaseList(cate_id) {
      this.showProductItem = false;
      let res = await getProductCaseList({cate_id, language_id: this.currentLanguage});
      this.list = res.data;
      this.showProductItem = true;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
