<template>
  <div class="custom-second-nav animated fadeInDown">
    <div class="nav-list">
      <div v-for="(item,index) in list" :key="index" :class="[value === index ? 'active' : '']" @click="$emit('input',index)">
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array
    }
  },

}
</script>

<style lang="scss" scoped>
.custom-second-nav {
  width: calc(100% - 4rem);
  margin: 0 auto;
  border-bottom: .1rem solid #f1f1f1;

  .nav-list {
    display: flex;
    justify-content: center;
    //padding: 2.5rem 0;

    & > div {
      padding: 2.5rem 0;

      font-size: 1.8rem;
      cursor: pointer;
      transition: all 0.5s;
      text-align: center;
      span{
        display: inline-block;
        padding: 0 7rem;
        border-right: .1rem solid #d5d5d5;
      }
      &:last-child {
        span{
          border-right: none;
        }
      }

      &.active {
        transition: all 0.5s;
        color: #ffffff;
        background-color: var(--default-color);

        span{
          border-color: transparent;
        }
      }

      &:hover {
        transition: all 0.5s;
        color: #ffffff;
        background-color: var(--default-color);

        span{
          border-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .custom-second-nav {
    .nav-list {

      & > div {
        padding: 0;
        //height: 100%;
        border-right: .1rem solid #d5d5d5;
        &:last-child {
          border-right: none;
        }
        span{
          display: inline-block;
          padding: 2rem;
          border-right: none;
          word-break: keep-all;
          word-wrap: break-word;
          writing-mode: vertical-rl;
        }
      }
    }
  }
}
</style>
