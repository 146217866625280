<template>
  <div class="__section-main__">

    <custom-nav v-model="currentIndex" :list="navList"></custom-nav>

    <div class="container">
      <div class="cx-fex-r">
        <div class="search-cont animated fadeInDown" style="margin-top: 4rem ">
          <input v-model="title" :placeholder="fixedTextData.search_name" type="text">
          <div class="search-btn" @click="handleSearch()">{{ fixedTextData.search }}</div>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="list-cont">
<!--        <template v-if="list.length > 0">-->
          <div v-for="item in list" :key="item.id" class="list-item wow fadeInUp" data-wow-offset="10"  @click="toNewsDetailPage(item.id)">
            <div class="left">
              <div :style="{backgroundImage:`url(${item.resource})`}" class="bg-img"></div>
            </div>
            <div class="right cx-fex cx-fex-column">
              <div>
                <div class="time desc-font-size-m">{{ item.create_time.substring(0, 10) }}</div>
                <div class="title">{{ item.title }}</div>
                <div class="desc zlc-t-hide4 desc-font-size-m">{{ item.introduction }}</div>
              </div>
              <div class="cx-fex-l">
                <div class="custom-btn-more">
                  <img alt="" src="@/assets/images/arrow-right-double.png">
                  {{ fixedTextData.more }}
                </div>
              </div>

            </div>
          </div>
<!--        </template>-->
        <!--        <div v-if="list.length === 0" class="list-no-data">暂无数据</div>-->
      </div>
    </div>
  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
import CustomNav from "@/components/customNav";
import {getNewsClassifyList, getNewsList} from "@/request/api";
import {mapState} from "vuex";
import {WOW} from "wowjs";

export default {
  components: {CustomNav},
  data() {
    return {
      currentIndex: 0,
      navList: [
        {title: '全部新闻', icon: 'cx-xinwen',},
        // {title: '新闻动态', icon: 'cx-xinwendongtai',},
        // {title: '业务动态', icon: 'cx-yewuzu',},
        // {title: '荣誉表彰', icon: 'cx-biaozhang',},
        // {title: '鹏鹞文化', icon: 'cx-wenhualvyou',},
      ],
      list: [],
      listQuery:{
        page:1,
        limit:99,
      },
      getMore:true,
      scrollReveal: scrollReveal(),
      title: '',
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData']),
  },
  watch: {
    '$route.query.firstIndex': {
      immediate: true,
      handler(e) {
        this.currentIndex = e ? e * 1 : 0;
      }
    },
    async currentIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: e,
        }
      })
      this.title = '';
      this.list = [];
      let classify_id = this.navList[e].id;
      this.listQuery.page = 1;
      await this.getNewsList(classify_id);

    }
  },
  async created() {

  },
  async mounted() {
    await this.getNewsClassifyList();
    window.addEventListener('scroll', this.throttle(this.handleScroll, 500), true)

  },
  methods: {
    handleScroll() {
      let reduceHeight = (document.documentElement.offsetHeight - document.documentElement.clientHeight) * 0.7;
      let scrollTop = document.documentElement.scrollTop;
      if (reduceHeight < scrollTop && this.getMore) {
        this.getMore = false;
        let classify_id = this.navList[this.currentIndex].id;
        this.getNewsList(classify_id);
      } else {
        return false;
      }
    },
    throttle(func, wait) {
      let lastTime = null
      let timeout
      return () => {
        let context = this;
        let now = new Date();
        let arg = arguments;
        if (now - lastTime - wait > 0) {
          if (timeout) {
            clearTimeout(timeout)
            timeout = null
          }
          func.apply(context, arg)
          lastTime = now
        } else if (!timeout) {
          timeout = setTimeout(() => {
            func.apply(context, arg)
          }, wait)
        }
      }
    },
    //获取动态分类列表
    async getNewsClassifyList() {
      let res = await getNewsClassifyList({language_id: this.currentLanguage});
      let classifyList = res.data;
      classifyList.forEach(item => {
        item.title = item.name;
        item.icon = item.resource;
      })
      classifyList = classifyList.filter(item => item.status === 1);
      this.navList = this.navList.concat(classifyList);
      this.navList[0].title = this.currentLanguage === 1 ? '全部新闻' : 'All News';
      await this.getNewsList(this.navList[this.currentIndex].id)

    },
    handleSearch() {
      this.getMore = true;
      this.listQuery.page = 1;
      this.list = [];
      this.getNewsList(this.navList[this.currentIndex].id)
    },
    //获取新闻列表
    async getNewsList(classify_id) {
      let data = {
        language_id: this.currentLanguage,
        classify_id,
        ...this.listQuery
      }
      if (this.title) {
        data.title = this.title;
      }
      let res = await getNewsList(data);
      this.list = this.list.concat(res.data.data);
      this.listQuery.page++;
      this.getMore = this.listQuery.page > res.data.last_page ? false : true;
      // this.$nextTick(() => {
      //   this.setScrollRevealClass();
      // })
      this.$nextTick(() => {
        new WOW().init();
      })

    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        // delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        // distance: '2rem',
        // 其他可用的动画效果
        // opacity: 0.001,
        // easing: 'linear',
        // scale: 0.9,

      })
    },
    toNewsDetailPage(id) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reveal-top{
  visibility: hidden;
}

.list-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  .list-item {
    display: flex;
    margin-bottom: 3rem;
    cursor: pointer;

    .left {
      width: 43.6rem;
      overflow: hidden;
      .bg-img {
        padding-bottom: 75%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.3s;
      }
    }

    .right {
      flex: 1;
      padding-left: 4rem;

      .time {
        font-size: 1.6rem;
        color: var(--default-color);
        padding-top: 3.5rem;
      }

      .title {
        font-size: 1.8rem;
        margin-top: 2rem;
        transition: all 0.3s;
      }

      .desc {
        font-size: 1.6rem;
        color: #666666;
        margin-top: 2rem;
        line-height: 2;
      }

      .btn {
        margin-top: 3rem;
        //width: 11rem;
        height: 4rem;
        padding: 0 3rem 0 3rem;
        line-height: 4rem;
        background-color: var(--default-color);
        text-align: center;
        position: relative;
        color: #ffffff;
        cursor: pointer;
        font-size: 1.6rem;

        img {
          position: absolute;
          right: .5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &:hover{
      .left{
        .bg-img{
          transform: scale(1.1);
        }
      }
      .right{
        .title{
          color: var(--default-color);
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {


  .list-cont {

    .list-item {
      flex-direction: column;

      .left {
        width: 100%;

        .bg-img {
          padding-bottom: 50%;
        }
      }

      .right {
        width: 100%;
        padding-left: 0;

        .time {
          font-size: 2.6rem;
        }

        .title {
          font-size: 3.4rem;
        }

        .desc {
          font-size: 2.4rem;
        }

        .btn {
          width: 18rem;
          height: 6rem;
          line-height: 6rem;
          padding-right: 1rem;


          img {
            width: 2.6rem;
            height: 1.8rem;
          }
        }
      }
    }
  }

}
</style>
