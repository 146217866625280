<template>
  <div class="flow-path">
    <div v-if="!$store.state.isMobile" :class="[fullpage.current === 3 ? ' animated fadeInDown' : 'animated fadeOutDown']" class="flow-path-nav">
      <div v-for="(item,index) in list" :key="index" :class="[{active:currentIndex === index}]" class="item" @click="changeCurrentIndex(index)">
        <div class="item-icon">
          <i :class="[item.image]" class="cx-icon"></i>
        </div>
        <div class="item-title">{{ item.name }}</div>
      </div>
    </div>
    <div class="cx-fex-c" style="margin-top: 7rem">
      <div :class="[fullpage.current === 3 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="left-cont">
        <div ref="flowPathSwiper" class="swiper-container section3-swiper" style="height: 100%">
          <div class="swiper-wrapper">
            <div v-for="(item,index) in list" :key="index" class="swiper-slide swiper-slide-visible">
              <div :style="{backgroundImage:`url(${item.resource})`}" class="left-item">
                <div class="mask"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="right-cont">
        <div class="custom-title" style="margin-bottom: 5rem">
          <div :class="[fullpage.current === 3 ? ' animated fadeInRight' : 'animated fadeOutRight']" class="custom-title-bg"></div>
          <div :class="[fullpage.current === 3 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="wz">
            <!-- {{ list[currentIndex].e_name }} <span>{{ list[currentIndex].name }}</span> -->
            {{ currentLanguage === 1 ? list[currentIndex].name : list[currentIndex].e_name }}
          </div>

        </div>
        <div :class="[fullpage.current === 3 ? ' animated fadeInRight' : 'animated fadeOutRight']" class="common-desc desc-font-size-m">
          {{ list[currentIndex].introduction }}
        </div>
        <div class="cx-fex-r" style="margin-top: 4rem">
          <div :class="[fullpage.current === 3 ? ' animated fadeInRight' : 'animated fadeOutRight']" class="custom-btn-more" @click="toMainBusinessDetail()">
            <img alt="" src="@/assets/images/arrow-right-double.png">
            {{ fixedTextData.more }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {mapState} from "vuex";

export default {
  props: {
    fullpage: {
      type: Object
    },
    sectionData: {
      type: Object
    }
  },
  computed: {
    ...mapState(['fixedTextData', 'currentLanguage']),
    list() {
      let list = this.sectionData.data;
      return list;
    }
  },
  data() {
    return {
      currentIndex: 0,
      swiper: null,
    }
  },
  mounted() {
    let _this = this;
    this.swiper = new Swiper('.section3-swiper', {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 8,
      centeredSlides: true,
      slideToClickedSlide: true,
      // loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChange: function () {
          // console.log(this.realIndex);
          _this.$set(_this, 'currentIndex', this.realIndex);
        },
      }
    });
  },
  methods: {
    toMainBusinessDetail() {
      console.log(this.currentIndex)
      this.$router.push({
        path: "/mainBusiness/detail",
        query: {
          firstIndex: this.currentIndex,
          secondIndex: 0
        }
      })
    },
    changeCurrentIndex(index) {
      // console.log(this.$refs.flowPathSwiper.swiper.realIndex)
      this.currentIndex = index;
      // this.$refs.flowPathSwiper.swiper.slideTo(index, 300, false)
      const loopedSlides = this.$refs.flowPathSwiper.swiper.loopedSlides || this.$refs.flowPathSwiper.swiper.params.loopedSlides; // 获取 loopedSlides 数量
      const slideToIndex = this.currentIndex + loopedSlides; // 转换为实际索引
      this.$refs.flowPathSwiper.swiper.slideTo(slideToIndex, 300, false); // 跳转到指定 slide
      this.$refs.flowPathSwiper.swiper.emit('slideChangeEnd'); // 手动触发 onSlideChangeEnd 回调函数
    },
  }
}
</script>

<style lang="scss" scoped>
.flow-path {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/index-flow-path-bg.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  .flow-path-nav {
    width: 155rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15rem auto 0;

    .item {
      cursor: pointer;
      transition: all 0.5s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .item-icon {
        width: 7rem;
        height: 7rem;
        background-color: #f3f3f3;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;

        i {
          font-size: 3.5rem;
          color: #666666;
        }
      }

      .item-title {
        font-size: 1.8rem;
        text-align: center;
        margin-top: 1.5rem;
        transition: all 0.5s;
      }

      &.active {
        transition: all 0.5s;

        .item-icon {
          background-color: var(--default-color);

          i {
            color: #ffffff;
          }
        }

        .item-title {
          color: var(--default-color);
        }
      }

      &:hover {
        transition: all 0.5s;

        .item-icon {
          background-color: var(--default-color);

          i {
            color: #ffffff;
          }
        }

        .item-title {
          color: var(--default-color);
        }
      }
    }
  }

  .left-cont {
    width: 92.5rem;
    height: 46.7rem;
    overflow: hidden;

    .swiper-slide {
      transition: 300ms;
      transform: scale(0.65);

      .left-item {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        position: relative;

        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.3);
          transition: all 0.3s;
        }


      }

      &:hover {
        transform: scale(0.8);

        .mask {
          background: rgba(0, 0, 0, 0);
        }
      }
    }

    .swiper-slide-active, .swiper-slide-duplicate-active {
      transform: scale(1);

      .left-item {
        .mask {
          display: none;
        }
      }

      &:hover {
        transform: scale(1);

        .mask {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .right-cont {
    width: 53.7rem;
    padding-left: 9rem;
    padding-top: 4rem;
    .desc {
      margin-top: 9rem;
      font-size: 1.6rem;
      line-height: 4.8rem;
      color: #666666;
    }
  }
}


@media screen and (max-width: 1024px) {
  .flow-path {

    .left-cont {
      width: 100%;
      margin: 20rem auto;
    }

    .right-cont {
      width: calc(100% - 20rem);
      padding: 0 10rem;

      .desc {
        margin-top: 9rem;
        font-size: 1.6rem;
        line-height: 4.8rem;
        color: #666666;
      }
    }
  }
}
</style>
