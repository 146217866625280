<template>
  <div class="business-contact">
    <common-title :en="navData.e_name" :zh="navData.name"></common-title>
    <div class="container">
      <div class="business-nav animated bounceInUp">
        <div v-for="(item,index) in navData.children" :key="index" :class="[currentNavIndex === index ? 'active' : '']" class="nav-item" @click="currentNavIndex = index">
          {{ currentLanguage === 1 ? item.name : item.e_name }}
          <div class="triangle"></div>
        </div>
      </div>

      <div class="main-cont animated zoomIn">
        <div v-for="(item,index) in list" :key="index" :class="[currentIndex === index ? 'active' : '']" class="main-item" @click="currentIndex = index">
          <div>{{ item.name }}</div>
          <div>{{ item.scale }}</div>
          <div>{{ item.description }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import {mapState} from "vuex";
import {getBusinessCaseList} from "@/request/api";

export default {
  components: {CommonTitle},
  props: {
    navData: {
      type: Object
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  },
  data() {
    return {
      currentNavIndex: 0,
      navList: [],
      currentIndex: 0,
      list: []
    }
  },
  watch: {
    currentNavIndex(e) {
      this.currentIndex = 0;
      this.getBusinessCaseList();
    }
  },
  created() {
    this.getBusinessCaseList();
  },
  methods: {
    async getBusinessCaseList() {
      console.log(this.navData)
      let res = await getBusinessCaseList({language_id: this.currentLanguage, business_cate_id: this.navData.children[this.currentNavIndex].id});
      this.list = res.data;
    },
  }

}
</script>

<style lang="scss" scoped>
.business-contact {
  .container {
    width: 120rem;
    margin: 0 auto;
  }

  .business-nav {
    display: flex;
    justify-content: space-between;
    padding-top: 4rem;

    .nav-item {
      width: 37rem;
      height: 7rem;
      margin: 0 1.5rem;
      background-color: #dedede;
      font-size: 1.8rem;
      text-align: center;
      line-height: 7rem;
      color: #999999;
      transition: all .5s;
      cursor: pointer;

      .triangle {
        width: 0;
        height: 0;
        border-width: 2rem;
        border-style: solid;
        border-color: transparent transparent transparent transparent;
        margin: 0 auto;
        transition: all .5s;
      }

      &.active,
      &:hover {
        transition: all .5s;
        background-color: var(--default-color);
        color: #ffffff;

        .triangle {
          border-color: var(--default-color) transparent transparent transparent;
          transition: all .5s;
        }
      }

    }
  }

  .main-cont {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 7rem auto 0;
    padding-bottom: 10rem;

    .main-item {
      width: calc(37rem - 4.5rem);
      height: 20rem;
      font-size: 1.6rem;
      color: #333333;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: .1rem solid #dedede;
      margin: 1.5rem;
      transition: all 0.5s;
      padding: 0 2rem;
      cursor: pointer;

      & > div {
        margin-bottom: 2.5rem;
      }

      &.active,
      &:hover {
        background-color: var(--default-color);
        border-color: var(--default-color);
        color: #ffffff;
        transition: all 0.5s;
      }
    }

  }
}

@media screen and (max-width: 1024px) {
  .business-contact {
    width: 90%;
    margin: 0 auto;

    .container {
      width: 100%;
      padding: 0;
    }

    .business-nav {
      .nav-item {
        width: calc(33% - 3rem);
      }
    }

    .main-cont {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 7rem;
      padding-bottom: 10rem;

      .main-item {
        width: 100%;
        height: auto;
        padding: 5rem 0;
        font-size: 3rem;
      }

    }
  }
}
</style>
