<template>
  <div>
    <template v-if="!$store.state.isMobile">
      <div v-if="showHeader" :style="{height:`${$store.state.isMobile ? '15rem' : '10rem'}`}" style="width: 100%;"></div>
      <div :style="{backgroundImage:hasHeaderBg?`url(${require('@/assets/images/banner-bg.png')})`:'',backgroundColor:hasHeaderBg?`rgba(255, 255, 255, 1)`:'rgba(255, 255, 255, 0.6)',}" class="web-header animated fadeInUp">
<!--        <div class="language" @click="showLanguageCont = !showLanguageCont">-->
<!--          <i class="cx-icon cx-diqiu" style="font-size: 2rem;"></i>-->
<!--          <span>{{ currentLanguage ? languageList.filter(item => item.id === currentLanguage)[0].name : languageList[0].name }}</span>-->
<!--          <i class="cx-icon cx-jiantouxia" style="font-size: 1.8rem"></i>-->

<!--          <div :class="{active:showLanguageCont}" class="language-list">-->
<!--            <div-->
<!--                v-for="item in languageList"-->
<!--                :key="item.id"-->
<!--                :class="[{active:item.id === currentLanguage}]"-->
<!--                class="list-item"-->
<!--                @click.stop.prevent="changeLanguage(item.id)"-->
<!--            >-->
<!--              {{ item.name }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="logo-cont">
          <img alt="" src="@/assets/images/header-logo.png" @click="toHomePage()">
          <div class="code-cont">
            <div :style="{letterSpacing: currentLanguage === 1 ? '.2rem' : ''}">{{ currentLanguage === 1 ? '股票代码' : 'Stock Code' }}</div>
            <div class="code" :style="{letterSpacing: currentLanguage === 1 ? '' : '.3rem'}">300664</div>
          </div>
        </div>

        <div class="nav-cont">
          <div
              v-for="(item,index) in navigationList"
              :key="index"
              :class="{active:item.link===$route.path || ($route.path === '/mainBusiness/detail' && item.link === '/mainBusiness')}"
              class="nav-item"
              @click="toPage(item.link,index)"
          >
            {{ item.name }}

            <ul v-if="item.children && item.children.length > 0" class="second-nav">
              <li
                  v-for="(item1,index1) in item.children"
                  :key="index1"
                  class="second-nav-item"
                  @click.stop="toSecondPage(item1.link?item1.link:item.link,index1)"
              >
                {{ item1.name }}

                <ul v-if="item1.children && item1.children.length > 0" class="third-nav">
                  <li
                      v-for="(item2,index2) in item1.children"
                      :key="index2"
                      class="third-nav-item"
                      @click.stop="toSecondPage(item1.link?item1.link:item.link,index1,index2)"
                  >
                    {{ item2.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.state.isMobile">
      <div v-if="showHeader" style="width: 100%;height: 15rem"></div>
      <div
          :style="{backgroundImage:hasHeaderBg?`url(${require('@/assets/images/banner-bg.png')})`:''}"
          class="web-header-mobile animated bounceInUp"
      >
<!--        <div class="language" @click.stop="showLanguageCont = !showLanguageCont">-->
<!--          <i class="cx-icon cx-diqiu" style="font-size: 2rem;"></i>-->
<!--          <span>{{ currentLanguage ? languageList.filter(item => item.id === currentLanguage)[0].name : languageList[0].name }}</span>-->
<!--          <i class="cx-icon cx-jiantouxia" style="font-size: 1.8rem"></i>-->

<!--          <div :class="{active:showLanguageCont}" class="language-list">-->
<!--            <div-->
<!--                v-for="item in languageList"-->
<!--                :key="item.id"-->
<!--                :class="[{active:item.id === currentLanguage}]"-->
<!--                class="list-item"-->
<!--                @click.stop.prevent="changeLanguage(item.id)"-->
<!--            >-->
<!--              {{ item.name }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="logo-cont">
          <img alt="" src="@/assets/images/header-logo.png" @click="toHomePage()">
          <div class="code-cont">
            <div>{{ currentLanguage === 1 ? '股票代码' : 'Stock Code' }}</div>
            <div class="code">300664</div>
          </div>
        </div>
        <div class="cx-fex-r" style="flex: 1">
          <i
              class="cx-icon cx-caidan-2"
              style="font-size: 7rem;color: var(--default-color)"
              @click.stop="showMobileNav = !showMobileNav"
          ></i>
        </div>
        <div :class="[showMobileNav ? 'open' : '']" class="nav-cont">
          <div
              v-for="(item,index) in navigationList"
              :key="index"
              :class="{active:item.link===$route.path || ($route.path === '/mainBusiness/detail' && item.link === '/mainBusiness')}"
              class="nav-item"
              @click="toPage(item.link,index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </template>

  </div>


</template>

<script>

import {mapState} from "vuex";
import localStorage from "@/utils/localStorage";

export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    hasHeaderBg: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'languageList', 'navigationList', 'isMobile']),
  },
  data() {
    return {
      navList: [
        {title: '首页', path: '/'},
        {title: '关于鹏鹞', path: '/about'},
        {title: '主营业务', path: '/mainBusiness'},
        {title: '技术产品', path: '/productList'},
        {title: '经典案例', path: '/classicCases'},
        {title: '企业动态', path: '/newsList'},
        {title: '人才中心', path: '/talentCenter'},
        {title: '投资者关系', path: ''},
        {title: '下载中心', path: ''},
      ],
      showMobileNav: false,
      showLanguageCont: false,
    }
  },
  watch: {
    "$route.path"(e) {
      this.showMobileNav = false
    }
  },
  mounted() {
    let _this = this;
    if (this.isMobile) {
      document.addEventListener('click', function (ev) {
        _this.showLanguageCont = false;
        _this.showMobileNav = false;
        ev.stopPropagation();
        ev.preventDefault();
      })
    }

  },
  methods: {
    toSecondPage(path, index1, index2) {
      console.log(path)
      this.$router.push({
        path,
        query: {
          firstIndex: path === '/newsList'? index1 + 1 : index1 ? index1 : 0,
          secondIndex: index2
        }
      })
    },
    toHomePage() {
      this.$router.push({
        path: '/',
      })
    },
    toPage(path, index) {
      if (index === this.navigationList.length - 2) {
        window.open(path)
      } else {
        this.$router.push({
          path,
        })
      }
    },
    changeLanguage(languageId) {
      this.showLanguageCont = false;
      if (this.currentLanguage === languageId) return;
      localStorage.set('currentLanguage', languageId);
      this.$store.commit("setCurrentLanguage", languageId);
      window.location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
.web-header {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 22rem);
  padding: 0 11rem;
  height: 10rem;
  display: flex;
  align-items: flex-end;
  z-index: 1000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .language {
    width: 12.5rem;
    height: 4.4rem;
    border: .1rem solid var(--default-color);
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--default-color);
    margin-right: 3.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    position: relative;

    span {
      margin-right: 1.5rem;
      margin-left: 1rem;
    }


    .language-list {
      position: absolute;
      width: 100%;
      top: 4.4rem;
      left: -0.1rem;
      max-height: 0;
      overflow: hidden;
      border: .1rem solid transparent;
      transition: all 0.5s;

      .list-item {
        width: 100%;
        line-height: 4.4rem;
        text-align: center;
        color: var(--default-color);
        background-color: #ffffff;
        transition: all 0.5s;

        &.active {
          background-color: var(--default-color);
          color: #ffffff;
        }
      }

      &.active {
        transition: all 0.5s;
        border-color: var(--default-color);
        max-height: 10rem;

      }
    }
  }

  .logo-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;

    img {
      width: 23.4rem;
      height: 4.9rem;

    }

    .code-cont {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 2.5rem;
      margin-left: 2.5rem;
      border-left: .3rem solid #004EA2;
      color: #004EA2;

      div {
        font-size: 1.6rem;

        &.code {
          font-size: 2rem;
        }
      }
    }
  }

  .nav-cont {
    margin-left: 8rem;
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: .1rem solid rgba(255, 255, 255, .1);

    .nav-item {
      font-size: 1.8rem;
      padding-bottom: 1.5rem;
      border-bottom: .4rem solid transparent;
      color: #333333;
      cursor: pointer;
      position: relative;

      &.active {
        color: var(--default-color);
        border-color: var(--default-color);
      }

      .second-nav {
        position: absolute;
        width: auto;
        left: 50%;
        top: 4rem;
        transform: translateX(-50%);
        color: #333333;
        background-color: #f4f4f4;
        //display: none;
        visibility: hidden;
        opacity: 0;
        transition: all .3s linear;
        z-index: 1000;

        .second-nav-item {
          line-height: 5rem;
          white-space: nowrap;
          text-align: center;
          padding: 0 4rem;
          position: relative;
          transition: all .3s linear;

          .third-nav {
            position: absolute;
            width: auto;
            right: 0;
            top: 0;
            color: #333333;
            background-color: #f4f4f4;
            transform: translateX(100%);
            //display: none;
            visibility: hidden;
            opacity: 0;
            transition: all .3s linear;

            .third-nav-item {
              line-height: 5rem;
              white-space: nowrap;
              text-align: center;
              padding: 0 4rem;
              transition: all .3s linear;

              &:hover {
                color: #ffffff;
                background-color: var(--default-color);
              }
            }
          }

          &:hover {
            & .third-nav {
              //display: block;
              visibility: visible;
              opacity: 1;
            }
          }

          &:hover {
            color: #ffffff;
            background-color: var(--default-color);
          }
        }


      }

      &:hover {
        .second-nav {
          //display: block;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .web-header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 10rem);
    padding: 0 5rem;
    height: 15rem;
    display: flex;
    align-items: center;
    z-index: 1000;
    background-color: rgba(255, 255, 255, .6);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .language {
      width: 16.5rem;
      height: 6.4rem;
      border: .1rem solid var(--default-color);
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--default-color);
      margin-right: 3.5rem;
      margin-bottom: 1rem;
      cursor: pointer;
      position: relative;

      span {
        margin-right: 1.5rem;
        margin-left: 1rem;
      }


      .language-list {
        position: absolute;
        width: 100%;
        top: 6.4rem;
        left: -0.1rem;
        max-height: 0;
        overflow: hidden;
        border: .1rem solid transparent;
        //transition: all 0.5s;

        .list-item {
          width: 100%;
          line-height: 6.4rem;
          text-align: center;
          color: var(--default-color);
          background-color: #ffffff;
          //transition: all 0.5s;

          &.active {
            background-color: var(--default-color);
            color: #ffffff;
          }
        }

        &.active {
          //transition: all 0.5s;
          border-color: var(--default-color);
          max-height: 30rem;

        }
      }
    }

    .logo-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: .5rem;

      img {
        width: 23.4rem;
        height: 4.9rem;

      }

      .code-cont {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 2.5rem;
        margin-left: 2.5rem;
        border-left: .3rem solid #004EA2;
        color: #004EA2;

        div {
          font-size: 3rem;

          &.code {
            font-size: 3rem;
          }
        }
      }
    }

    .nav-cont {
      position: absolute;
      width: 100%;
      left: 0;
      top: 15rem;
      overflow: hidden;
      max-height: 0;
      //transition: all 0.5s;

      .nav-item {
        width: 100%;
        text-align: center;
        background: #ffffff;
        font-size: 4rem;
        line-height: 10rem;
        color: #333333;
        cursor: pointer;

        &.active {
          color: #ffffff;
          background-color: var(--default-color);
        }
      }

      &.open {
        max-height: 1000rem;
      }
    }
  }
}
</style>
