<template>
  <div class="__section-main__" style="padding-top: 20rem">
    <template v-if="navList.length > 0">
      <div class="classic-header" style="">
        <custom-nav v-model="currentIndex" :list="navList" @input="changeCurrentIndex"></custom-nav>
        <custom-second-nav v-model="currentSecondIndex" :list="navList[currentIndex].children"></custom-second-nav>

      </div>

      <!--      <product-item v-if="showProductItem" :leftData="navList[currentIndex].children[currentSecondIndex]" :list="list"></product-item>-->
      <div class="classic-case" style="">
        <div v-for="(item) in secondNavList" :key="item.id">
          <div :ref="`secondBoxTitle${item.id}`" class="cx-fex-l">
            <common-title :en="item.e_name" :zh="item.name"></common-title>
          </div>


          <div :style="{padding:isMobile?'0 3.5rem':'0'}" class="cx-fex-l">
            <div v-for="(item1) in item.list" :key="item1.id" class="item-cont"
                 @click="showPopup(item1)">

              <div :style="{backgroundImage:`url(${$tool.getImage(item1.resource)})`}" class="image-cont"></div>
              <div class="bottom-cont">
                <div class="title zlc-t-hide">{{ item1.name }}</div>
                <div class="cx-fex cx-fex-itemsc" style="margin-top: 1.5rem">
                  <!--                    <div class="size">工程规模：{{item1.size}}</div>-->
                  <div class="size">{{ item1.scale }}</div>
                  <div class="btn">
                    <span>{{ fixedTextData.more }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </template>

    <custom-popup v-if="currentCase" v-model="popupVisible" :current-case="currentCase"></custom-popup>

  </div>
</template>

<script>
import CustomNav from "@/components/customNav";
import ProductItem from "@/components/productItem";
import {getClassicCasesCaseList, getClassicCasesCateList} from "@/request/api";
import {mapState} from "vuex";
import CustomSecondNav from "@/components/customSecondNav";
import CommonTitle from "@/components/commonTitle";
import CustomPopup from "@/components/customPopup";

export default {
  components: {CustomPopup, CommonTitle, CustomSecondNav, CustomNav},
  data() {
    return {
      currentIndex: 0,
      currentSecondIndex: 0,
      navList: [],
      showProductItem: true,
      list: [],

      secondNavList: [],

      scrollTopList: [],

      currentCase: '',
      popupVisible: false,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData', 'isMobile']),
  },
  created() {
    this.getClassicCasesCateList();
  },
  mounted() {

  },
  watch: {
    '$route.query.firstIndex': {
      immediate: true,
      handler(e) {
        this.currentIndex = e ? e * 1 : 0;
      }
    },
    '$route.query.secondIndex': {
      immediate: true,
      handler(e) {
        this.currentSecondIndex = e ? e * 1 : 0;
      }
    },
    currentIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: e,
          secondIndex: 0
        }
      })
      this.secondNavList = this.navList[e].children;
      this.getClassicCasesCaseList();
    },
    currentSecondIndex: {
      immediate: true,
      handler(e) {
        // this.$router.replace({
        //   query: {
        //     firstIndex: this.currentIndex,
        //     secondIndex: e
        //   }
        // })
        if (this.secondNavList.length > 0 && this.secondNavList[e].list && this.secondNavList[e].list.length > 0) {
          this.$nextTick(() => {
            document.documentElement.scrollTop = this.scrollTopList[e];
            document.body.scrollTop = this.scrollTopList[e];
          })
          // alert(this.scrollTopList[e])
        } else {
          setTimeout(() => {
            // window.scrollTo({top:this.scrollTopList[e]})
            document.documentElement.scrollTop = this.scrollTopList[e];
            document.body.scrollTop = this.scrollTopList[e];
          }, 1000)
        }
      }
    },


  },
  methods: {
    showPopup(item) {
      this.currentCase = item;
      this.popupVisible = true;
    },
    changeCurrentIndex() {
      this.currentSecondIndex = 0;
    },
    async getClassicCasesCateList() {
      let res = await getClassicCasesCateList({language_id: this.currentLanguage});
      let navList = res.data;
      navList = navList.map(item => {
        return {
          ...item,
          title: item.name,
          icon: item.image,
        }
      })
      this.navList = navList;
      this.secondNavList = this.navList[this.currentIndex].children;
      await this.getClassicCasesCaseList();
    },
    async getClassicCasesCaseList() {
      // this.showProductItem = false;
      // let res = await getClassicCasesCaseList({
      //   classics_cate_id: this.navList[this.currentIndex].children[this.currentSecondIndex].id,
      //   language_id: this.currentLanguage
      // });
      // this.list = res.data;
      // this.showProductItem = true;
      this.scrollTopList = [];
      for (let i = 0; i < this.secondNavList.length; i++) {
        let secondNavItem = this.secondNavList[i];
        let res = await getClassicCasesCaseList({
          classics_cate_id: this.secondNavList[i].id,
          language_id: this.currentLanguage
        });
        secondNavItem.list = res.data;
        this.$set(this.secondNavList, i, secondNavItem);
      }
      this.$nextTick(() => {
        let bodyWidth = document.documentElement.clientWidth;
        this.secondNavList.forEach(item => {
          let element = this.$refs['secondBoxTitle' + item.id];
          if (this.isMobile) {
            this.scrollTopList.push(element[0].getBoundingClientRect().top - 180);
          } else {
            this.scrollTopList.push(element[0].getBoundingClientRect().top - (bodyWidth * 0.140625));
          }

          console.log(this.scrollTopList)
        })
      })

    },
  }
}
</script>

<style lang="scss" scoped>
.classic-case {
  width: 132.3rem;
  margin: 0 auto;
  padding-bottom: 8rem
}

.classic-header {
  width: 100%;
  position: fixed;
  top: 10rem;
  left: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 1)
}

.item-cont {
  width: 42.1rem;
  height: 32.1rem;
  margin: 1rem;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  overflow: hidden;

  .image-cont {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
  }

  .bottom-cont {
    width: calc(100% - 4rem);
    padding: 5rem 2rem 3rem;
    background-image: linear-gradient(to bottom, transparent 10%, rgba(9, 103, 209, .8));
    //background-color: rgba(9,103,209,.5);
    //background: transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #ffffff;

    .title {
      line-height: 3rem;
      border-bottom: .1rem solid #ffffff;
      font-size: 1.8rem;
    }

    .size {
      font-size: 1.4rem;
    }

    .btn {
      width: 6.7rem;
      height: 2.1rem;
      background-image: url("@/assets/images/product-more-bg.png");
      color: var(--default-color);
      font-size: 1.0rem;
      line-height: 2.1rem;
      cursor: pointer;

      span {
        display: block;
        transform: scale(0.8);
      }
    }
  }

  &:hover {
    .image-cont {
      transform: scale(1.2);
    }
  }
}

@media screen and (max-width: 1024px) {
  .classic-case {
    width: 100%;
  }
  .classic-header {
    top: 15rem;
  }

}
</style>
