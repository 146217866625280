<template>
  <div class="__section-main__">
    <custom-nav v-model="currentIndex" :list="navList"></custom-nav>
    <about-section1 v-if="currentIndex === 0"></about-section1>
    <about-section2 v-if="currentIndex === 1"></about-section2>
    <about-section3 v-if="currentIndex === 2"></about-section3>
    <about-section4 v-if="currentIndex === 3"></about-section4>
    <about-section5 v-if="currentIndex === 4"></about-section5>
  </div>
</template>

<script>
import CustomNav from "@/components/customNav";
import AboutSection3 from "@/views/about/aboutSection3";
import AboutSection1 from "@/views/about/aboutSection1";
import AboutSection2 from "@/views/about/aboutSection2";
import AboutSection4 from "@/views/about/aboutSection4";
import AboutSection5 from "@/views/about/aboutSection5";
import {mapState} from "vuex";

export default {
  components: {AboutSection5, AboutSection4, AboutSection2, AboutSection1, AboutSection3, CustomNav},
  data() {
    return {
      currentIndex: 0,
      // navList: [
      //   {title: '企业简介', icon: 'cx-qiyejianjie'},
      //   {title: '发展历程', icon: 'cx-fazhan'},
      //   {title: '资质荣誉', icon: 'cx-rongyu'},
      //   {title: '企业文化', icon: 'cx-wenhualvyou'},
      //   {title: '制造园区', icon: 'cx-renyuanguanli'},
      // ],

    }
  },
  computed:{
    ...mapState(['navigationList']),
    navList(){
      if(this.navigationList && this.navigationList.length > 0){
        let iconList = ['cx-qiyejianjie','cx-fazhan','cx-rongyu','cx-wenhualvyou','cx-renyuanguanli'];
        let navList = this.navigationList.filter(item=>item.link === '/about')[0].children;
        navList = navList.map((item,index)=>{
          return {title:item.name,icon:iconList[index]}
        })
        return navList;
      }else {
        return [];
      }

    }
  },
  watch: {
    '$route.query.firstIndex': {
      immediate: true,
      handler(e) {
        this.currentIndex = e ? e * 1 : 0;
      }
    },
    currentIndex:{
      // immediate: true,
      handler(e) {
        this.$router.replace({
          query: {
            firstIndex: e
          }
        });
      }
    }
  },
  created() {

  },
  mounted() {
    this.currentIndex = this.$route.query.firstIndex ? this.$route.query.firstIndex * 1 : 0;
  }
}
</script>

<style lang="scss" scoped>
.title-cont {
  text-align: center;
  padding: 5rem 0;

  .title {
    font-size: 4rem;
    color: var(--default-color);
  }

  .subtitle {
    font-size: 2.6rem;
    color: #999999;
  }
}

.honor {
  width: calc(100% - 33rem);
  height: 70rem;
  margin: 0 auto;
  padding-bottom: 5rem;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      //transition: 300ms;
      //transform: scale(0.9);

      .main-cont {
        margin-top: 2rem;

        .img-cont {
          width: 42.2rem;
          height: 59.7rem;
          background-color: #ffffff;
          box-shadow: 0 0 2rem rgba(0, 0, 0, 0.08);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .title {
          width: 42.2rem;
          font-size: 1.8rem;
          color: #666666;
          margin-top: 2rem;
          text-align: center;
          display: none;
        }
      }
    }

    .swiper-slide-active, .swiper-slide-duplicate-active {
      //transform: scale(1);
      //z-index: 10;
      .main-cont {
        .title {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .title-cont {
    padding: 15rem 0;
  }
  .honor {
    width: calc(100% - 5rem);
    overflow: hidden;
  }
}
</style>
