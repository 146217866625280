<template>
  <div id="app">
    <web-header v-if="showWebHeader" :hasHeaderBg="true"></web-header>
    <router-view/>
    <web-footer v-if="showWebFooter"></web-footer>
  </div>
</template>
<script>
import {remFn} from "@/utils/rem";
import WebHeader from "@/components/web-header";
import WebFooter from "@/components/web-footer";
import {getFooterData, getLanguageData, getLanguageList, getNavigationList} from "@/request/api";
import localStorage from "@/utils/localStorage";
import {mapState} from "vuex";

export default {
  components: {WebFooter, WebHeader},
  computed: {
    ...mapState(['currentLanguage', 'languageList']),
    showWebHeader() {
      let _data = false;
      if (this.$route.path !== '/') {
        _data = true
      }
      return _data
    },
    showWebFooter() {
      let _data = false;
      // if(this.$route.path !== '/' && !this.$store.state.isMobile){
      if (this.$route.path !== '/') {
        _data = true
      }
      return _data
    },
  },
  created() {
    this.getLanguageList();
  },
  mounted() {
    this.reloadRem()
    window.addEventListener("resize", this.reloadRem, {passive: false});
  },
  methods: {
    async getLanguageList() {
      let res = await getLanguageList();
      let languageList = res.data;
      this.$store.commit('setLanguageList', languageList);
      localStorage.set('languageList', languageList);
      if (!this.currentLanguage) {
        this.$store.commit('setCurrentLanguage', this.languageList[0].id);
        localStorage.set('currentLanguage', this.languageList[0].id)
      }
      await this.getNavigationList(this.currentLanguage);
      await this.getLanguageData(this.currentLanguage);
      await this.getFooterData(this.currentLanguage);
    },
    async getNavigationList(language_id) {
      let res = await getNavigationList({language_id});
      this.$store.commit('setNavigationList', res.data);
    },
    async getLanguageData(language_id) {
      let res = await getLanguageData({language_id});
      this.$store.commit("setFixedTextData", res.data[0]);
    },
    async getFooterData(language_id) {
      let res = await getFooterData({language_id});
      this.$store.commit('setFootData', res.data);
    },
    reloadRem() {
      // remFn(1920, 1920)
      let windowWidth = document.documentElement.getBoundingClientRect().width;
      if (windowWidth <= 750) {
        this.$store.commit('setIsMobile', true);
        remFn(968, 1024)
      } else if (windowWidth <= 1024 && windowWidth > 750) {
        this.$store.commit('setIsMobile', true);
        remFn(1920, 1920)
      } else {
        console.log("1920")
        this.$store.commit('setIsMobile', false);
        remFn(1920, 3840)
      }
    },
  }
}
</script>
<style lang="scss">

:root {
  //--light-background-color: #172234;
  //--deep-background-color: #10192A;
  --default-color: #0077DA;
}

</style>
