<template>
  <div :style="{backgroundImage:`url(${$tool.getImage(sectionData[1].resource)})`}" class="bg">
    <img :class="[fullpage.current === 5 ? 'animated zoomInRight' : 'animated zoomOutRight']" :src="$tool.getImage(sectionData[0].resource)" alt="" class="wz">
    <div :class="[fullpage.current === 5 ? 'animated fadeInDown' : '']" class="footer ">
      <web-footer></web-footer>
    </div>
  </div>
</template>

<script>
import WebFooter from "@/components/web-footer";

export default {
  components: {WebFooter},
  props: {
    fullpage: {
      type: Object
    },
    sectionData: {
      type: [Object, Array]
    }

  },
  data() {
    return {}
  },
  mounted() {


  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .wz {
    position: absolute;
    transform: scale(0.8) translate(-60%,-110%);
    left: 50%;
    top: 50%;
  }

  .footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .bg {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .wz {

      max-width: 90%;
      top: 40rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .footer {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
</style>
