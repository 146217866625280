<template>
  <div class="__section-main__">
    <common-title en="MAIN BUSINESS" zh="主营业务"></common-title>
    <!--    <div v-if="currentLanguage === 1" class="business-desc desc-font-size-m animated fadeInUp">-->
    <!--      公司利用自身强大的环保专业技术能力和资源整合优势，结合“专注、创新、专业、有效”的服务理念，“专注”于环保行业35年，持续“创新”，对现有环保问题进行“专业”分析并进行“有效”的处理解决。-->
    <!--    </div>-->
    <!--    <div v-if="currentLanguage === 2" class="business-desc desc-font-size-m animated fadeInUp">-->
    <!--      The company takes advantage of its strong environmental protection professional technical ability and resource integration advantages, combined with the service-->
    <!--      concept of "focus, innovation, professionalism, and effectiveness", has "focused" on the environmental protection industry for 35 years, continued to "innovate",-->
    <!--      and "professionalized" the existing environmental protection issues. "Analyze and carry out "effective" processing and resolution.-->
    <!--    </div>-->

    <div class="flow-path">
      <div v-if="list.length > 0" class="cx-fex-c" style="margin-top: 7rem;margin-bottom: 10rem">
        <div class="left-cont animated fadeInLeft">
          <div ref="flowPathSwiper" class="swiper-container" style="height: 100%">
            <div class="swiper-wrapper">
              <div v-for="(item,index) in list" :key="index" class="swiper-slide swiper-slide-visible">
                <div :style="{backgroundImage:`url(${item.resource})`}" class="left-item">
                  <div class="mask"></div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="right-cont">
          <div class="custom-title">
            <div class="custom-title-bg animated fadeInRight"></div>
            <div class="wz animated fadeInLeft">
              <!--              {{ list[currentIndex].e_name }} <span>{{ list[currentIndex].name }}</span>-->
              {{ currentLanguage === 1 ? list[currentIndex].name : list[currentIndex].e_name }}
            </div>

          </div>
          <div class="desc desc-font-size-m animated fadeInRight">
            {{ list[currentIndex].introduction }}
          </div>
          <div class="cx-fex-r" style="margin-top: 4rem">
            <div class="custom-btn-more animated fadeInRight" @click="toMainBusinessDetail()">
              <img alt="" src="@/assets/images/arrow-right-double.png">
              {{ fixedTextData.more }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {getBusinessList} from "@/request/api";
import {mapState} from "vuex";
import CommonTitle from "@/components/commonTitle";
// import "swiper/swiper-bundle.css"

export default {
  components: {CommonTitle},
  data() {
    return {
      list: [],
      currentIndex: 0,
      swiper: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData'])
  },
  created() {
    this.getBusinessList();
  },
  mounted() {


  },

  methods: {
    async getBusinessList() {
      let res = await getBusinessList({language_id: this.currentLanguage});
      this.list = res.data;
      console.log(res);
      this.$nextTick(() => {
        this.renderSwiper();
      })
    },

    renderSwiper() {
      let _this = this;
      this.swiper = new Swiper('.swiper-container', {
        slidesPerView: 3,
        spaceBetween: 8,
        centeredSlides: true,
        slideToClickedSlide: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange: function () {
            // console.log(this.realIndex);
            _this.$set(_this, 'currentIndex', this.realIndex);
            console.log(this.activeIndex)
          },
        }
      });
    },
    toMainBusinessDetail() {
      this.$router.push({
        path: "/mainBusiness/detail",
        query: {
          firstIndex: this.currentIndex,
          secondIndex: 0,
        }
      })
    },
    changeCurrentIndex(index) {
      // console.log(this.$refs.flowPathSwiper.swiper.realIndex)
      this.currentIndex = index;
      // this.$refs.flowPathSwiper.swiper.slideTo(index, 300, false)
      const loopedSlides = this.$refs.flowPathSwiper.swiper.loopedSlides || this.$refs.flowPathSwiper.swiper.params.loopedSlides; // 获取 loopedSlides 数量
      const slideToIndex = this.currentIndex + loopedSlides; // 转换为实际索引
      this.$refs.flowPathSwiper.swiper.slideTo(slideToIndex, 300, false); // 跳转到指定 slide
      this.$refs.flowPathSwiper.swiper.emit('slideChangeEnd'); // 手动触发 onSlideChangeEnd 回调函数
    },
  }
}
</script>

<style lang="scss" scoped>

.business-desc {

  margin: 0 auto;
  width: 80rem;
  font-size: 1.6rem;
  color: #666666;
  line-height: 2;
  text-align: justify;
}

.flow-path {
  width: 100%;
  //height: calc(100vh - 10rem);
  //margin-bottom: 20rem;
  overflow: hidden;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  .left-cont {
    width: 92.5rem;
    height: 46.7rem;
    overflow: hidden;


    .swiper-slide {
      transition: 300ms;
      transform: scale(0.65);

      .left-item {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;
        position: relative;

        .mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.3);
        }
      }

      &:hover {
        transform: scale(0.8);

        .mask {
          background: rgba(0, 0, 0, 0);
        }
      }
    }

    .swiper-slide-active, .swiper-slide-duplicate-active {
      transform: scale(1);

      .left-item {
        .mask {
          display: none;
        }
      }

      &:hover {
        transform: scale(1);

        .mask {
          background: rgba(0, 0, 0, 0);
        }
      }
    }


  }

  .right-cont {
    width: 53.7rem;
    padding-left: 9rem;
    padding-top: 5rem;

    .desc {
      margin-top: 5rem;
      font-size: 1.6rem;
      line-height: 2;
      color: #666666;
    }
  }
}

@media screen and (max-width: 1024px) {
  .main-business-title {
    padding-top: 12rem;

    .desc {
      width: calc(100% - 10rem);
      padding: 0 5rem;
    }
  }

  .flow-path {
    overflow: hidden;

    .left-cont {
      width: calc(100% - 10rem);
      padding: 0 5rem;
      height: 46.7rem;

    }

    .right-cont {
      width: calc(100% - 20rem);
      padding: 10rem;

      .desc {
        margin-top: 9rem;
        font-size: 1.6rem;
        line-height: 4.8rem;
        color: #666666;
      }
    }
  }
}
</style>
