<template>
  <div v-if="cultureAdData " class="">
    <div class="corporate-culture" style="padding-bottom: 6rem">
      <!--    <common-title en="CORPORATE CULTURE" zh="企业文化"></common-title>-->
      <common-title :en="cultureAdData.e_name" :zh="cultureAdData.name"></common-title>


      <div class="swiper-container culture-list animated zoomIn">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in cultureList" :key="index" class="swiper-slide">
            <div class="culture-item ">
              <div :style="{backgroundImage:`url(${$tool.getImage(item.resource)})`}" class="item-img"></div>
              <div class="right-cont">
                <div class="item-title">{{ item.name }}</div>
                <div class="item-tip">{{ item.introduction }}</div>
                <div v-for="(item1,index1) in item.description.split('\n').filter(item1=>item1)" :key="index1" class="item-list">
                  <div class="list-item">
                    <div class="dot"></div>
                    <div class="desc desc-font-size-m">{{ item1 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination culture-pagination"></div>
      </div>
    </div>


    <div class="habits-cont">
      <div class="corporate-culture">
        <common-title class="reveal-top" en="TOP 10 GOOD HABITS" zh="十大好习惯"></common-title>
        <div class="swiper-container habits-swiper reveal-top">
          <div class="swiper-wrapper">
            <div v-for="(item,index) in habitsList" :key="index" class="swiper-slide">
              <div class="habits-list">
                <div v-for="(item1,index1) in item" :key="index1" class="habit-item" @click="handleShowHabit(item1)">
                  <div :style="{backgroundImage:`url(${item1.image})`}" class="icon"></div>
                  <div class="num">{{ item1.number < 10 ? ('0' + item1.number) : ('' + item1.number) }}</div>
                  <div class="word">{{ item1.name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination habits-pagination"></div>
        </div>
      </div>

    </div>
    <!--    <div class="reveal-top" style="margin-top: 5rem">-->
    <!--      <common-title en="PENGYAO TOP 10 GOOD HABITS" zh="鹏鹞十大好习惯"></common-title>-->
    <!--    </div>-->
    <!--    <div class="habits-list">-->
    <!--      <div v-for="(item,index) in habitsList" :key="index" class="habits-item-cont reveal-top">-->
    <!--        <div class="habits-item ">-->
    <!--          <div class="header">-->
    <!--            <div class="index">{{ index + 1 < 10 ? ('0' + (index + 1)) : (index + 1) }}</div>-->
    <!--            <div :style="{backgroundImage:`url(${item.image})`}" class="icon"></div>-->
    <!--          </div>-->
    <!--          <div class="word">{{ item.name }}</div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->


    <div class="corporate-culture">
      <div class="reveal-top">
        <!--      <common-title en="CORPORATE CULTURE" zh="团队展示"></common-title>-->
        <common-title :en="teamAdData.e_name" :zh="teamAdData.name"></common-title>
      </div>

      <div v-if="!isMobile" class="team-cont reveal-top">
        <div
            v-for="(item,index) in teamList"
            :key="index"
            :class="[currentTeamIndex === index ? 'active' : '']"
            :style="{backgroundImage: `url(${$tool.getImage(item.resource)})`}"
            class="team-item"
            @mouseenter="currentTeamIndex = index"
        >
          <div class="mask"></div>
          <img :src="$tool.getImage(item.image)" alt="" class="item-icon"/>
          <div class="item-title">{{ item.name }}</div>
          <div class="item-desc zlc-t-hide4">{{ item.description }}</div>
        </div>
      </div>

      <div v-if="isMobile" class="team-cont">
        <div
            v-for="(item,index) in teamList"
            :key="index"
            :style="{backgroundImage: `url(${$tool.getImage(item.resource)})`}"
            class="team-item active reveal-top"
        >
          <div class="mask"></div>
          <img :src="$tool.getImage(item.image)" alt="" class="item-icon"/>
          <div class="item-title">{{ item.name }}</div>
          <div class="item-desc">{{ item.description }}</div>
        </div>
      </div>
    </div>


    <div v-if="popupVisible" class="habits-popup">
      <div class="popup-bg" @click="popupVisible = false"></div>
      <div class="popup-content animated fadeInDown">

        <div class="word-cont">
          <div class="title">{{ currentHabit.title || '' }}</div>
          <!--          <div class="time">发表日期：{{ currentCase.create_time }}</div>-->

          <div :style="{height: isMobile?'50rem':'20rem'}" class="custom-scroll-cont">
            <div class="desc" v-html="currentHabit.description"></div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import scrollReveal from "scrollreveal";
import {getCompanyCulture, getCompanyHabitList} from "@/request/api";
import {mapState} from "vuex";
import Swiper from "swiper";

export default {
  components: {CommonTitle},
  data() {
    return {
      cultureAdData: null,
      cultureList: [],
      teamList: [],
      teamAdData: null,
      currentTeamIndex: 0,

      habitsList: [],
      scrollReveal: scrollReveal(),
      popupVisible: false,
      currentHabit: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'isMobile'])
  },
  async created() {
    await this.getCompanyCulture();
    await this.getCompanyHabitList();
  },
  mounted() {

  },
  methods: {
    handleShowHabit(item) {
      this.currentHabit = item;
      this.popupVisible = true;
    },
    renderSwiper() {
      new Swiper('.culture-list', {
        loop: true,
        pagination: {
          el: ".culture-pagination",
          clickable: true,
        },
      })
    },
    renderHabitsSwiper() {
      new Swiper('.habits-swiper', {
        // loop: true,
        pagination: {
          el: ".habits-pagination",
          clickable: true,
        },
      })
    },

    async getCompanyHabitList() {
      let res = await getCompanyHabitList({language_id: this.currentLanguage});
      this.habitsList = res.data;
      this.habitsList.forEach((item, index) => {
        item.image = require(`@/assets/images/habits-icon-${index + 1}.png`);
        item.number = (index + 1);
      })


      if (this.isMobile) {
        this.habitsList = [
          [this.habitsList[0], this.habitsList[1], this.habitsList[2]],
          [this.habitsList[3], this.habitsList[4], this.habitsList[5]],
          [this.habitsList[6], this.habitsList[7], this.habitsList[8]],
          [this.habitsList[9]],
        ]
      } else {
        this.habitsList = [
          [this.habitsList[0], this.habitsList[1], this.habitsList[2], this.habitsList[3], this.habitsList[4],],
          [this.habitsList[5], this.habitsList[6], this.habitsList[7], this.habitsList[8], this.habitsList[9],],
        ]
      }
      this.$nextTick(() => {
        this.setScrollRevealClass();
        this.renderHabitsSwiper();
      })
    },
    async getCompanyCulture() {
      let res = await getCompanyCulture({language_id: this.currentLanguage});
      console.log(res);
      this.cultureAdData = res.data.culture_adList[0];
      this.cultureList = res.data.culture;
      this.teamList = res.data.employee_activities;
      this.teamAdData = res.data.employee_activities_adList[0];
      this.$nextTick(() => {
        this.renderSwiper();
      })
    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: '4rem',
        // 其他可用的动画效果
        opacity: 0.001,
        // easing: 'linear',
        scale: 0.9,
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.habits-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1100;

  .popup-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup-content {
    width: 92rem;
    overflow: hidden;
    border-radius: 2rem;
    background-color: #ffffff;
    position: absolute;
    top: 25rem;
    left: 50%;
    margin-left: -46rem;
    //transform: translate(-50%, -50%);

    .image-cont {
      width: 100%;
      height: 34.5rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      color: #ffffff;
      font-size: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 3rem;
        margin-left: 2rem;
      }
    }

    .word-cont {
      padding: 5rem 8rem 5rem;

      .title {
        font-size: 1.8rem;
        color: var(--default-color);
      }

      .time {
        font-size: 1.6rem;
        color: #666666;
        margin: 1rem 0 1rem;
        padding-bottom: 1.5rem;
        border-bottom: .1rem solid #f1f1f1;
      }

      .size,
      .desc {
        font-size: 1.6rem;
        line-height: 2;
        color: #666666;
      }
    }
  }

}

.corporate-culture {
  width: 136.6rem;
  margin: 0 auto;

  .culture-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .culture-item {
      width: 100%;
      margin-bottom: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .item-img {
        width: 62.8rem;
        height: 30rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .right-cont {
        flex: 62.8rem;
        padding-left: 5.5rem;

        .item-title {
          font-size: 1.8rem;
          color: #333333;
          font-weight: bold;
          //margin-top: 1rem;
        }

        .item-tip {
          font-size: 1.6rem;
          color: var(--default-color);
          margin-top: 2rem;
        }

        .list-item {
          display: flex;
          justify-content: flex-start;
          margin-top: 2rem;

          .dot {
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            background-color: var(--default-color);
            margin-right: 2.2rem;
            margin-top: 1.3rem;
          }

          .desc {
            flex: 1;
            font-size: 1.6rem;
            color: #666666;
            line-height: 3.6rem;
          }
        }
      }

    }
  }


  .team-cont {
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 17rem;

    .team-item {
      width: 22rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #ffffff;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: default;
      transition: all 0.5s;
      position: relative;

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }

      .item-icon {
        width: 5.3rem;
        height: 5.3rem;
        transition: all 0.5s;
        position: relative;
        z-index: 10;
      }

      .item-title {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 2rem;
        transition: all 0.5s;
        position: relative;
        z-index: 10;
      }

      .item-desc {
        font-size: 1.6rem;
        line-height: 3.6rem;
        margin: 1rem 0 4rem 0;
        padding: 0 15rem 0 3.8rem;
        //max-height: 17.5rem;
        display: none;
        overflow: hidden;
        transition: all 0.5s;
        position: relative;
        z-index: 10;
      }


      &.active {
        width: 70.6rem;
        justify-content: flex-end;
        align-items: flex-start;
        //box-shadow: 0 0 1rem 0 rgba(0, 119, 218, 0.4);
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.5);
        position: relative;
        z-index: 10;

        .item-icon {
          padding-left: 3.8rem;
        }

        .item-title {
          padding-left: 3.8rem;
        }

        .item-desc {
          display: block;
          height: 14.7rem;
          max-height: 14.7rem;
        }
      }
    }

  }
}

.habits-cont {
  width: 100%;
  height: 63.4rem;
  background-image: url("@/assets/images/about-habits-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.habits-list {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 2rem;
  width: calc(100% - 4rem);

  .habit-item {
    width: 25rem;
    height: 30.8rem;
    background: #ffffff;
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      transform: translateY(-2rem);
    }

    .icon {
      width: 5.4rem;
      height: 6.7rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .num {
      font-size: 3.6rem;
      line-height: 1;
      margin-top: 2rem;
      color: var(--default-color);
      font-weight: bold;
    }

    .word {
      color: #666666;
      line-height: 1;
      margin-top: 3rem;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  //.habits-item-cont {
  //  width: 20%;
  //  margin-bottom: 8rem;
  //  .habits-item {
  //
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //    flex-direction: column;
  //    transition: all 2s;
  //
  //    .header {
  //      display: flex;
  //      justify-content: center;
  //
  //      .index {
  //        font-size: 4.8rem;
  //        color: var(--default-color);
  //        font-weight: bold;
  //      }
  //
  //      .icon {
  //        margin-left: 2rem;
  //        width: 5.4rem;
  //        height: 6.7rem;
  //        background-size: cover;
  //        background-repeat: no-repeat;
  //        background-position: center;
  //        transition: all 2s;
  //      }
  //    }
  //
  //    .word {
  //      font-size: 2.4rem;
  //      margin-top: 2.5rem;
  //      transition: all 2s;
  //    }
  //
  //    &:hover {
  //      //background-color: #000000;
  //
  //      .header{
  //        .icon{
  //          transform: rotate(360deg);
  //        }
  //      }
  //      .word {
  //        color: var(--default-color);
  //      }
  //    }
  //
  //  }
  //
  //}

}


@media screen and (max-width: 1024px) {
  .corporate-culture {
    width: 90%;
    margin: 0 auto;

    .culture-list {
      width: 100%;

      .culture-item {
        width: 100%;
        flex-direction: column;


        .item-img {
          width: 100%;
          height: 45rem;
        }

        .right-cont {
          margin-top: 5rem;
          padding-left: 0;
          padding-bottom: 5rem;

          .item-title {
            font-size: 4rem;
          }

          .item-tip {
            font-size: 3.4rem;
          }
        }

      }
    }

    .habits-list {

      .habits-item {
        width: 50%;

        .header {
          .index {
            font-size: 9.6rem;
          }

          .icon {
            margin-left: 4rem;
            width: 10.8rem;
            height: 13.4rem;
          }
        }

        .word {
          font-size: 3.6rem;
          margin-top: 2.5rem;
        }
      }
    }


    .team-cont {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 17rem;

      .team-item {
        width: 100%;
        height: 80rem;
        margin-top: 5rem;

        .item-icon {
          width: 8rem;
          height: 8rem;
          transition: all 0.5s;
        }

        .item-title {
          font-size: 4rem;

        }

        .item-desc {
          font-size: 3rem;
          line-height: 2;
          padding: 0 3.8rem;
        }

        &.active {
          width: 100%;
          justify-content: flex-end;
          align-items: flex-start;

          .item-icon {
            padding-left: 3.8rem;
          }

          .item-title {
            padding-left: 3.8rem;
          }

          .item-desc {
            height: 30rem;
            max-height: 30rem;
          }
        }
      }

    }
  }

  .habits-popup {
    .popup-content {
      top: 50rem;

      .word-cont {


        .title {
          font-size: 5rem;
          color: var(--default-color);
        }

        .custom-scroll-cont {
          .desc {
            p {
              line-height: 2 !important;
            }
          }
        }

      }
    }
  }

}
</style>

