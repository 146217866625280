<template>
  <div class="common-title">
    <div class="title-cont animated fadeInDown">
      <div class="title">{{ currentLanguage === 1 ? zh : en }}</div>
      <!--      <div class="subTitle">{{ currentLanguage === 1 ? en : zh }}</div>-->
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  props: {
    zh: {
      type: String,
      default: ''
    },
    en: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  }
}
</script>

<style lang="scss" scoped>
.common-title {
  display: flex;
  justify-content: center;

  .title-cont {
    //margin: 6rem;
    margin: 4rem;
    //height: 9.1rem;
    height: 6.1rem;
    background-image: url("@/assets/images/title-bg-logo.png");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    text-align: center;
    padding-left: 8rem;
    display: flex;
    align-items: center;
    //flex-direction: column;
    justify-content: center;

    .title {
      font-size: 3.6rem;
      color: var(--default-color);
      line-height: 1;
      //padding-bottom: 0.5rem;
    }

    .subTitle {
      font-size: 2.6rem;
      color: #999999;
      margin-top: 2rem;
      line-height: 1;
    }
  }
}

@media screen and (max-width: 1024px) {
  .common-title {
    margin: 8rem auto;

    .title-cont {
      height: 13rem;

      .title {
        font-size: 6rem;
      }

      .subTitle {
        font-size: 4rem;
      }
    }
  }
}

</style>
