<template>
  <div v-if="adData">
    <!--    <common-title en="HISTORY" zh="发展历程"></common-title>-->
<!--    <common-title :en="adData.e_name" :zh="adData.name"></common-title>-->
    <div class="history-cont">
      <div class="left">
        <custom-second-title class="animated fadeInLeft" en="History" zh="企业大事记"></custom-second-title>

        <div ref="customScrollCont" class="custom-scroll-cont animated zoomInLeft" @scroll="handleScroll" style="height: 55.8rem">
          <div ref="scrollCont" class="scroll-cont">
            <div v-for="(item,index) in list" :key="item.id" ref="scrollItem" class="scroll-item" :class="[index === currentIndex ? 'active' : '']">
              <div class="title">{{ item.name }}</div>
              <div class="desc">
                {{ item.introduction }}
              </div>
              <div class="circular"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div :style="{paddingLeft: `${isMobile?'':'12rem'}`}">
          <custom-second-title class="animated fadeInRight" en="Business" zh="业务涉及"></custom-second-title>
        </div>

        <div class="image-cont animated zoomInRight">
          <img src="@/assets/images/history-right.png" alt="">
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import CommonTitle from "@/components/commonTitle";
import CustomSecondTitle from "@/components/customSecondTitle";
import {getCompanyHistory} from "@/request/api";
import {mapState} from "vuex";

export default {
  components: {CustomSecondTitle},
  data() {
    return {
      currentIndex: 0,
      domOffsetTopList: [],
      list: [],
      adData: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'isMobile'])
  },
  created() {
    this.getCompanyHistory();
  },
  mounted() {

  },
  methods: {
    async getCompanyHistory() {
      let res = await getCompanyHistory();
      this.list = res.data.data;
      this.adData = res.data.adList[0];
      this.$nextTick(() => {
        this.getScrollItemOffsetTop();
      })
      console.log(res);
    },
    getScrollItemOffsetTop() {
      let arr = [];
      Array.from(this.$refs.scrollItem).forEach(item => {
        arr.push(item.offsetTop);
      })
      this.domOffsetTopList = arr;
    },
    handleScroll(ev) {
      let scrollTop = this.$refs.customScrollCont.scrollTop;
      for (let i = 0; i < this.domOffsetTopList.length; i++) {
        for (let j = 1; j < this.domOffsetTopList.length; j++) {
          if (scrollTop > this.domOffsetTopList[i] && scrollTop < this.domOffsetTopList[j]) {
            this.currentIndex = i + 1;
            break;
          }
        }
      }
      this.currentIndex = this.currentIndex < 0 ? 0 : this.currentIndex
      if (scrollTop === 0) {
        this.currentIndex = 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.history-cont {
  //padding-bottom: 22rem;
  padding-bottom: 10rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-top: 5rem;
  .left {
    width: 59rem;
    padding-left: 24.5rem;
  }

  .right {
    padding-left: 1rem;

    .image-cont {
      width: 95.12rem;
      height: 57.38rem;
      //margin-top: 13rem;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .history-cont {
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;

    .left {
      width: 100%;
      padding-left: 0;
    }

    .right {
      width: 100%;
      padding-left: 0;

      .image-cont {
        width: 100%;
        margin-top: 5rem;
      }
    }
  }
}
</style>
