<template>
  <div v-if="adData" style="margin: 0 auto">
    <div class="cx-fex-c cx-fex-column" style="overflow: hidden">
      <!--      <div class="title-cont">-->
      <!--        <div class="title animated bounceInLeft">资质荣誉</div>-->
      <!--        <div class="subtitle animated bounceInRight">HONOR</div>-->
      <!--      </div>-->
      <common-title :en="adData.e_name" :zh="adData.name"></common-title>
      <div class="common-desc desc-font-size-m" style="padding-left: 7rem;text-align: center;margin-bottom: 2rem">甲级设计 | 壹级工程 | 壹级运营</div>
      <div class="honor animated zoomInUp">
        <div ref="honorSwiper" class="swiper-container" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
          <div class="swiper-wrapper">
            <div v-for="(item,index) in list" :key="index" class="swiper-slide">
              <div class="main-cont">
                <div :style="{backgroundImage:`url(${item.resource})`}" class="img-cont"></div>
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Swiper from "swiper";
import {getCompanyHonors} from "@/request/api";
import {mapState} from "vuex";
import CommonTitle from "@/components/commonTitle";

export default {
  components: {CommonTitle},

  data() {
    return {
      list: [],
      adData: null,
      swiper:null,
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
  },
  created() {
    this.getCompanyHonors();
  },
  mounted() {


  },
  methods: {
    onMouseenter() {
      this.swiper.$el[0].swiper.autoplay.stop()
    },
    onMouseleave() {
      this.swiper.$el[0].swiper.autoplay.start()
    },
    async getCompanyHonors() {
      let res = await getCompanyHonors({language_id: this.currentLanguage});
      this.list = res.data.data;
      this.adData = res.data.adList[0];
      this.$nextTick(() => {
        this.renderSwiper();
      })
    },
    renderSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: this.$store.state.isMobile ? 2 : 4,
        spaceBetween: this.$store.state.isMobile ? 30 : 20,
        loop: true,
        autoplay: {
          delay:1000,
          disableOnInteraction: false,
        },
        speed:1000,
        coverflowEffect: {
          rotate: this.$store.state.isMobile ? 20 : 30,
          stretch: 0,
          depth: this.$store.state.isMobile ? 200 : 250,
          modifier: 1,
          slideShadows: false,
        },
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.honor-desc {
  color: #5a5a5a;
  font-size: 1.6rem;
  line-height: 3.6rem;
  margin-bottom: 3rem;
  text-align: center;
}

.honor {
  width: calc(100% - 33rem);
  height: 70rem;
  margin: 0 auto;
  padding-bottom: 5rem;
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-slide {
      //transition: 300ms;
      //transform: scale(0.9);

      .main-cont {
        margin-top: 2rem;

        .img-cont {
          width: 42.2rem;
          height: 59.7rem;
          background-color: #ffffff;
          box-shadow: 0 0 2rem rgba(0, 0, 0, 0.08);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: all 1s;
          &:hover {
            box-shadow: 0 0 2rem rgba(0, 119, 218,0.25);
          }
        }

        .title {
          width: 42.2rem;
          font-size: 1.6rem;
          color: #666666;
          margin-top: 2rem;
          text-align: center;
          display: none;
        }
      }
    }

    .swiper-slide-active, .swiper-slide-duplicate-active {
      //transform: scale(1);
      //z-index: 10;
      .main-cont {
        .title {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .honor {
    width: calc(100% - 5rem);
    overflow: hidden;
  }
}
</style>
