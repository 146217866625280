<template>
  <div class="__section-main__">
    <template v-if="navList.length > 0">
      <custom-nav v-model="currentNavIndex" :list="navList"></custom-nav>

      <talent-section1 v-if="currentNavIndex === 0" :cate-data="navList[0]"></talent-section1>
      <talent-section2 v-if="currentNavIndex === 1" :cate-data="navList[1]"></talent-section2>
      <talent-section3 v-if="currentNavIndex === 2" :cate-data="navList[2]"></talent-section3>
    </template>

  </div>
</template>

<script>
import CustomNav from "@/components/customNav";
import TalentSection1 from "@/views/talentCenter/talentSection1";
import TalentSection3 from "@/views/talentCenter/talentSection3";
import TalentSection2 from "@/views/talentCenter/talentSection2";
import {getTalentCateList} from "@/request/api";
import {mapState} from "vuex";

export default {
  components: {TalentSection2, TalentSection3, TalentSection1, CustomNav},
  data() {
    return {
      currentNavIndex: 0,
      navList: []
    }
  },
  computed: {
    ...mapState(['currentLanguage'])
  },
  watch: {
    '$route.query.firstIndex': {
      immediate: true,
      handler(e) {
        this.currentNavIndex = e ? e * 1 : 0;
      }
    },
    currentNavIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: e,
        }
      })
    }
  },
  created() {
    this.getTalentCateList();
  },
  methods: {
    async getTalentCateList() {
      let res = await getTalentCateList({language_id: this.currentLanguage});
      let navList = res.data;
      navList = navList.map(item => {
        return {
          ...item,
          title: item.name,
          icon: item.image,
        }
      })
      this.navList = navList;
    }
  }
}
</script>

<style scoped>

</style>
