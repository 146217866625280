<template>
  <div class="service">
    <div :style="{flexDirection: `${$store.state.isMobile ? 'column-reverse' : ''}`}" class="cx-fex-c" style="margin-top: 5rem">
      <div class="left-cont">
        <div class="custom-title">
          <div :class="[fullpage.current === 4 ? ' animated fadeInRight' : 'animated fadeOutRight']" class="custom-title-bg"></div>
          <div :class="[fullpage.current === 4 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="wz">
            <!--            {{ list[currentIndex].e_name }} <span>{{ list[currentIndex].name }}</span>-->
            {{ currentLanguage === 1 ? list[currentIndex].name : list[currentIndex].e_name }}
          </div>

        </div>

        <!--        <div ref="customScrollCont" class="custom-scroll-cont animated zoomInLeft" style="height: 30rem">-->
        <!--          <div ref="scrollCont" class="scroll-cont">-->
        <!--            <div :class="[fullpage.current === 4 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="desc desc-font-size-m">-->
        <!--              {{ list[currentIndex].introduction }}-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div ref="customScrollCont" :style="{height: isMobile ? `calc(100vh - 115rem)` : '40rem',marginTop:'5rem'}" class="custom-scroll-cont"
             @DOMMouseScroll="mouseWheelHandle"
             @mousewheel="mouseWheelHandle"
             @touchend="handleTouchend"
             @touchmove="handleTouchmove"
             @touchstart="handleTouchstart">
          <div :class="[fullpage.current === 4 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="desc desc-font-size-m">
            {{ list[currentIndex].introduction }}
          </div>
        </div>

        <!--        <div class="cx-fex-r" style="margin-top: 4rem">-->
        <!--          <div :class="[fullpage.current === 4 ? ' animated fadeInLeft' : 'animated fadeOutLeft']" class="custom-btn-more">-->
        <!--            <img alt="" src="@/assets/images/arrow-right-double.png">-->
        <!--            {{ fixedTextData.more }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div :class="[fullpage.current === 4 ? 'animated zoomInRight' : 'animated zoomOutRight']" class="right-cont">
        <div class="round rotate2"></div>
        <div class="bg2 rotate"></div>
        <div :class="[fullpage.current === 4 ? 'animated fadeIn' : 'animated fadeOut']" class="severs">
          <div class="cx-fex-c cx-fex-itemsc cx-fex-column">
            <div>{{ currentLanguage === 1 ? '我们的服务' : 'SEVERS' }}</div>
            <!--            <div style="margin-top: 2rem;"></div>-->
          </div>
          <!--          <div class="cx-fex-c cx-fex-itemsc cx-fex-column" v-if="currentLanguage === 2">-->
          <!--            <div>SEVERS</div>-->
          <!--            <div style="margin-top: 2rem;">我们的服务</div>-->
          <!--          </div>-->
        </div>
        <div class="main">
          <div v-for="(item,index) in list" :key="index" :class="[currentIndex===index?'active':'']" class="main-item" @click="changeCurrentIndex(index)">
            <div class="main-item-cont">
              <i :class="[item.resource]" class="cx-icon"></i>
              <div class="">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  props: {
    fullpage: {
      type: Object
    },
    sectionData: {
      type: Object
    }
  },
  computed: {
    ...mapState(['fixedTextData', 'currentLanguage', 'isMobile']),
    list() {
      return this.sectionData.data;
    },
  },
  data() {
    return {
      currentIndex: 0,
      swiper: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      let customScrollCont = this.$refs.customScrollCont;
      console.log(customScrollCont)
      customScrollCont.addEventListener('scroll', function (event) {
        console.log(123123123)
        event.stopPropagation();
      })
    })

  },
  methods: {
    mouseWheelHandle(event) {
      event.stopPropagation();
    },
    handleTouchend(event) {
      event.stopPropagation();
    },
    handleTouchmove(event) {
      event.stopPropagation();
    },
    handleTouchstart(event) {
      event.stopPropagation();
    },
    changeCurrentIndex(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate {
  animation: rotate 40s linear infinite; /* 动画名称、持续时间、动画速度、动画循环次数 */
}

.rotate2 {
  animation: rotate2 40s linear infinite; /* 动画名称、持续时间、动画速度、动画循环次数 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg); /* 开始时的旋转角度 */
  }
  to {
    transform: rotate(360deg); /* 结束时的旋转角度 */
  }
}

@keyframes rotate2 {
  from {
    transform: rotate(0deg); /* 开始时的旋转角度 */
  }
  to {
    transform: rotate(-360deg); /* 结束时的旋转角度 */
  }
}

.service {
  width: 100%;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .left-cont {
    width: 53.7rem;
    //padding-left: 20.5rem;
    padding-right: 17rem;
    padding-top: 10rem;

    .desc {
      //margin-top: 4rem;
      font-size: 1.6rem;
      line-height: 2;
      color: #666666;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      //-webkit-line-clamp: 9;
    }
  }

  .right-cont {
    width: 68rem;
    height: 68rem;

    //padding-right: 11.5rem;
    position: relative;

    .round {
      width: 100%;
      height: 100%;
      background-image: url("@/assets/images/index-service-bg-1.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }

    .bg2 {
      width: 38.046666rem;
      height: 36.766666rem;
      background-image: url("@/assets/images/index-service-bg-2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -19rem;
      margin-left: -18.38rem;
      //transform: translate(-50%,-50%);
    }

    .severs {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 3.6rem;
    }

    .main {
      width: 48rem;
      height: 48rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: .1rem dashed #dddddd;
      border-radius: 100%;

      .main-item {
        width: 10.4rem;
        height: 10.4rem;
        padding: .3rem;
        border-radius: 100%;
        border: .1rem dashed #666666;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg) translate(100px) rotate(-45deg);
        outline: none;
        overflow: hidden;

        .main-item-cont {
          width: calc(100% - .3rem);
          height: calc(100% - .3rem);
          border: .1rem solid #666666;
          border-radius: 100%;
          background-color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          transition: all 0.5s;
          outline: none;
          overflow: hidden;

          i {
            font-size: 4rem;
            color: #666666;
          }

          div {
            font-size: 1.6rem;
            margin-top: .5rem;
            color: #666666;
          }
        }

        &.active {
          border-color: var(--default-color);

          .main-item-cont {
            border-color: var(--default-color);
            background-color: var(--default-color);

            i {
              color: #ffffff;
            }

            div {
              color: #ffffff;
            }
          }
        }


        &:nth-child(1) {
          transform: translate(-50%, -50%) rotate(0deg) translate(23.7rem) rotate(0deg);
        }

        &:nth-child(2) {
          transform: translate(-50%, -50%) rotate(45deg) translate(23.7rem) rotate(-45deg);
        }

        &:nth-child(3) {
          transform: translate(-50%, -50%) rotate(90deg) translate(23.7rem) rotate(-90deg);
        }

        &:nth-child(4) {
          transform: translate(-50%, -50%) rotate(135deg) translate(23.7rem) rotate(-135deg);
        }

        &:nth-child(5) {
          transform: translate(-50%, -50%) rotate(180deg) translate(23.7rem) rotate(-180deg);
        }

        &:nth-child(6) {
          transform: translate(-50%, -50%) rotate(225deg) translate(23.7rem) rotate(-225deg);
        }

        &:nth-child(7) {
          transform: translate(-50%, -50%) rotate(270deg) translate(23.7rem) rotate(-270deg);
        }

        &:nth-child(8) {
          transform: translate(-50%, -50%) rotate(315deg) translate(23.7rem) rotate(-315deg);
        }

        &:hover {
          border-color: var(--default-color);

          .main-item-cont {
            border-color: var(--default-color);
            background-color: var(--default-color);

            i {
              color: #ffffff;
            }

            div {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .service {
    position: relative;

    .left-cont {
      width: calc(100% - 20rem);
      padding: 0 10rem;
      position: absolute;
      top: 90rem;
      left: 0;

      //.desc-font-size-m{
      //  font-size: 2.4rem!important;
      //}
    }

    .right-cont {
      margin: 0;
      position: absolute;
      top: 12rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
