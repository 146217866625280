<template>
  <div class="web-footer" v-if="footData">
    <div class="top-cont">
      <ul>
        <li>{{ footData.contact.name }}</li>
        <li v-for="(item,index) in footData.contact.introduction.split('\n')" :key="index">
          {{ item }}
        </li>
        <!--        <li>总机：（86-510）88568063</li>-->
        <!--        <li>传真：（86-510）88568063</li>-->
        <!--        <li>邮箱：pengyao@pengyao.com.cn (企业邮箱)</li>-->
        <!--        <li>地址：江苏省 宜兴市 高胜镇 鹏鹞科技创新园</li>-->
      </ul>
      <div class=" cx-fex-itemsc" :class="[isMobile?'cx-fex-c':'cx-fex-r']">
        <div class="qrcode-content">
          <img :src="footData.contact.qy_resource" alt="">
          <div>企业二维码</div>
        </div>
        <div class="qrcode-content">
          <img :src="footData.contact.sp_resource" alt="">
          <div>视频号二维码</div>
        </div>
      </div>
    </div>

    <div class="bottom-cont">
      <div class="copyRight">
        <!--        Copyright@2009 鹏鹞环保股份有限公司 <a href="">苏ICP备17019226号-2</a>-->
        <a target="_blank" :href="footData.contact.link">{{ footData.contact.put_on_record }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  computed: {
    ...mapState(['footData', 'isMobile'])
  }
}
</script>

<style lang="scss" scoped>
.web-footer {
  width: 100%;
  height: 30rem;
  background-image: url("@/assets/images/footer-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.85;
  position: relative;
  font-size: 1.6rem;

  .top-cont {
    padding: 4rem 21rem 0 62rem;
    opacity: 1;
    display: flex;
    justify-content: space-between;

    ul {
      li {
        font-size: 1.6rem;
        color: #ffffff;
        //margin-bottom: 2rem;
        line-height: 2;
        &:nth-of-type(1) {
          font-size: 1.6rem;
        }
      }
    }

    .qrcode-content {
      img {
        width: 12rem;
        height: 12rem;
      }

      div {
        font-size: 1.6rem;
        margin-top: 2rem;
        text-align: center;
        color: #ffffff;
      }

      &:nth-of-type(1) {
        margin-right: 6rem;
      }
    }
  }

  .bottom-cont {
    padding-left: 48rem;
    padding-right: 9.6rem;
    width: calc(100% - 62rem);
    height: 7.5rem;
    position: absolute;
    left: 0;
    bottom: 0;

    .copyRight {
      line-height: 7.4rem;
      border-top: .1rem solid #ececec;
      font-size: 1.6rem;
      color: #ffffff;

      a {
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .web-footer {
    width: 100%;
    height: 80rem;
    background-image: url("@/assets/images/footer-bg.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.85;
    position: relative;

    .top-cont {
      padding: 8rem;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      ul {
        li {
          font-size: 3rem;
          color: #ffffff;
          margin-bottom: 2rem;

          &:nth-of-type(1) {
            font-size: 4rem;
          }
        }
      }

      .qrcode-content {
        //display: none;
        margin-top: 3rem;
      }
    }

    .bottom-cont {
      padding-left: 8rem;
      padding-right: 8rem;
      padding-bottom: 2rem;
      width: calc(100% - 16rem);
      height: auto;

      .copyRight {
        line-height: 6rem;
        font-size: 2.6rem;
        text-align: center;
      }
    }
  }

}
</style>
