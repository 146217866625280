import Vue from 'vue'
import Vuex from 'vuex'
import localStorage from "@/utils/localStorage";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMobile: false,
        languageList: localStorage.get('languageList') || [],
        currentLanguage: localStorage.get('currentLanguage') || 1,  //1:中文，2:英文
        navigationList: [],
        footData: null,
        fixedTextData: {},
    },
    getters: {},
    mutations: {
        setIsMobile: (state, payload) => {
            state.isMobile = payload;
        },
        setCurrentLanguage: (state, payload) => {
            state.currentLanguage = payload;
        },
        setLanguageList: (state, payload) => {
            state.languageList = payload;
        },
        setNavigationList: (state, payload) => {
            state.navigationList = payload;
        },
        setFootData: (state, payload) => {
            state.footData = payload;
        },
        setFixedTextData: (state, payload) => {
            state.fixedTextData = payload;
        }
    },
    actions: {},
    modules: {}
})
