<template>
  <div class="__section-main__" style="background-color: #f8f8f8">
    <div v-if="detail" class="container" style="padding-top: 3rem;padding-bottom: 5rem">
      <div class="detail-main">
        <div class="cx-fex cx-fex-itemsc">
          <div class="cx-fex-l cx-fex-column" style="flex: 1">
            <div class="title">{{ detail.title }}</div>
            <div class="time">{{ fixedTextData.publication_date }}：{{ detail.create_time }}</div>
          </div>

          <div class="back" @click="$router.back()">
            <i class="cx-icon cx-fanhui"></i>返回
          </div>
        </div>
        <div class="line"></div>

        <div class="content" v-html="detail.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsDetail} from "@/request/api";
import {mapState} from "vuex";

export default {
  data() {
    return {
      detail: null,
      newsId: '',
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData']),
  },
  created() {
    this.newsId = this.$route.query.id ? this.$route.query.id : '';
    this.getNewsDetail();
  },
  methods: {
    async getNewsDetail() {
      let res = await getNewsDetail({id: this.newsId, language_id: this.currentLanguage});
      this.detail = res.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-main {
  padding: 3rem 5rem 6rem;
  background-color: #ffffff;

  .title {
    font-size: 2.4rem;
    color: #333333;
  }

  .time {
    font-size: 1.6rem;
    color: #999999;
    margin-top: 2.5rem;
  }

  .back {
    padding: 0 4rem;
    color: var(--default-color);
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;

    i {
      margin-right: 1rem;
      font-size: 1.8rem;
    }
  }

  .line {
    width: 100%;
    height: .1rem;
    background-color: #f8f8f8;
    margin: 3rem 0;
  }

  .content {
    & > div {
      font-size: 1.6rem;
      color: #666666;
      line-height: 4.8rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .detail-main {
    padding: 3rem 5rem 6rem;
    background-color: #ffffff;

    .title {
      font-size: 5rem;
      line-height: 1.5;
    }

    .time {
      font-size: 3.4rem;
      margin-top: 3.5rem;
    }

    .back {
      display: none !important;
    }
  }

}
</style>
