import Vue from 'vue';
import {
    BASE_URL,
    IMAGE_PATH
} from '@/config/config.js';

Vue.filter('defaultImg', function (data) {
    if (!data) {
        data = '';
        return data
    } else {
        if (data.indexOf(IMAGE_PATH) !== -1) {
            return data
        } else {
            return IMAGE_PATH + data
        }
    }
})

Vue.filter('defaultUserIcon', function (data) {
    if (!data || typeof (data) == "undefined" || data == 0 || data == 'null') {
        data = 'https://pic-sphsine-com.oss-cn-shanghai.aliyuncs.com/yanfeng-mp/usericon.png';
        return data
    } else {
        if (data.indexOf(IMAGE_PATH) !== -1) {
            console.log(data)
            return data
        } else {
            return IMAGE_PATH + data
        }
    }
})

Vue.filter('defaultIMAvatar', function (data) {
    if (!data || typeof (data) == "undefined" || data == 0 || data == 'null') {
        data = 'https://sdk-web-1252463788.cos.ap-hongkong.myqcloud.com/component/TUIKit/assets/avatar_21.png';
        return data
    } else {
        if (data.indexOf(IMAGE_PATH) != -1) {
            console.log(data)
            return data
        } else {
            return IMAGE_PATH + data
        }
    }
})
