<template>
  <div class="__section-main__">
    <custom-nav v-model="currentIndex" :list="navList" @input="changeCurrentIndex"></custom-nav>
    <template v-if="currentIndex < (navList.length - 1)">
      <custom-second-nav v-model="currentSecondIndex" :list="this.navList[this.currentIndex].children"></custom-second-nav>
      <product-item v-if="showProductItem" :left-data="navList[currentIndex].children[currentSecondIndex]" :list="list"></product-item>
    </template>
    <template v-else>
      <business-contact v-if="navList.length > 0" :navData="navList[currentIndex]"></business-contact>
    </template>

  </div>
</template>

<script>
import CustomNav from "@/components/customNav";
import ProductItem from "@/components/productItem";
import CustomSecondNav from "@/components/customSecondNav";
import BusinessContact from "@/views/mainBusiness/details/businessContact";
import {mapState} from "vuex";
import {getBusinessCaseList, getBusinessList} from "@/request/api";

export default {
  components: {BusinessContact, CustomSecondNav, ProductItem, CustomNav},
  computed: {
    ...mapState(['currentLanguage'])
  },
  watch: {
    '$route.query.firstIndex': {
      immediate: true,
      handler(e) {
        this.currentIndex = e ? e * 1 : 0;
      }
    },
    '$route.query.secondIndex': {
      immediate: true,
      handler(e) {
        this.currentSecondIndex = e ? e * 1 : 0;
      }
    },
    currentIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: e,
          secondIndex: 0
        }
      })
      this.currentSecondIndex = 0;
      if (e !== 5) {
        console.log(this.navList[this.currentIndex].children[0].id)
        this.getBusinessCaseList(this.navList[this.currentIndex].children[0].id)
      }

    },
    currentSecondIndex(e) {
      this.$router.replace({
        query: {
          firstIndex: this.currentIndex,
          secondIndex: e
        }
      })
      console.log(this.navList[this.currentIndex].children[0].id)
      console.log(e);
      this.getBusinessCaseList(this.navList[this.currentIndex].children[e].id);
    }
  },
  data() {
    return {
      currentIndex: 0,
      navList: [],
      currentSecondIndex: 0,
      list: [],
      showProductItem: true,
    }
  },
  created() {
    this.currentIndex = this.$route.query.firstIndex ? this.$route.query.firstIndex * 1 : 0;
    this.currentSecondIndex = this.$route.query.secondIndex ? this.$route.query.secondIndex * 1 : 0;
    this.getBusinessList();
  },
  methods: {
    changeCurrentIndex(e) {
      this.currentSecondIndex = 0
    },
    async getBusinessList() {
      let res = await getBusinessList({language_id: this.currentLanguage});
      let navList = res.data;
      navList = navList.map(item => {
        return {
          title: item.name,
          icon: item.image,
          ...item
        }
      })
      this.navList = navList;

      await this.getBusinessCaseList(this.navList[this.currentIndex].children[this.currentSecondIndex].id)
    },

    async getBusinessCaseList(cateId) {
      this.showProductItem = false;
      let res = await getBusinessCaseList({language_id: this.currentLanguage, business_cate_id: cateId});
      this.list = res.data;
      this.showProductItem = true;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
