<template>
  <div class="common-nav-cont">
    <div class="container">
      <div class="nav-list ">
        <div v-for="(item,index) in list" :key="index" :class="[{active:value === index}]" class="nav-item animated fadeInDown" @click="$emit('input',index)">
          <i :class="[item.icon]" class="cx-icon"></i>
          <div class="nav-title">{{ item.title }}</div>
          <div class="bottom-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    }
  }
}
</script>

<style lang="scss" scoped>
.common-nav-cont {
  width: calc(100% - 4rem);
  margin: 0 auto;
  border-bottom: .1rem solid #f1f1f1;

  .nav-list {
    width: 100%;
    //height: 15rem;
    height: 12rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #333333;
      padding-bottom: 2rem;
      line-height: 1;
      //border-bottom: .4rem solid #ffffff;
      cursor: pointer;
      //width: 12rem;
      transition: all 0.5s;
      position: relative;

      i {
        font-size: 4.5rem;
      }

      .nav-title {
        text-align: center;
        margin-top: 2rem;
        font-size: 1.8rem;
      }

      .bottom-line {
        width: 0;
        height: .4rem;
        background-color: var(--default-color);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.5s;
      }

      &.active,
      &:hover {
        transition: all 0.5s;
        color: var(--default-color);

        //border-color: var(--default-color);
        .bottom-line {
          width: 100%;
        }
      }


    }

  }
}

</style>
