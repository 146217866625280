<template>
  <div v-if="parkData" class="park">
    <!--    <common-title en="MANUFACTURING PARK" zh="制造园区"></common-title>-->
    <common-title :en="parkData.e_name" :zh="parkData.name"></common-title>

    <div class="park-main">
      <div class="left animated fadeInLeft">
        <video :src="parkData.video" controls></video>
        <div class="left-title">{{ parkData.title }}</div>
        <div class="left-desc desc-font-size-m">
          {{ parkData.introduct }}
        </div>
      </div>

      <div class="right animated fadeInRight">
        <div v-for="(item,index) in parkData.children" :key="index" class="right-item">
          <div class="item-title">{{ item.name }}</div>
          <div class="list-cont">
            <div v-for="(item1,index1) in item.children" :key="index1" :class="[index===2?'list-item-100':'list-item-50']" class="list-item">
              <div v-if="index !== 2" class="zlc-t-hide">{{ item1.name }}</div>
              <div v-if="index === 2 && index1 < 4" class="zlc-t-hide">{{ item1.name }}</div>
            </div>
          </div>
          <div class="cx-fex-r">
            <div class="enter" @click="toParkDetailPage(item.id)">{{ fixedTextData.click }} <i class="cx-icon cx-jiantouyou"></i></div>
          </div>
          <div :style="{left:`${index % 2 ? '' : 0}`,right:`${index % 2 ? 0 : ''}`}" class="bottom-line"></div>
        </div>
      </div>
    </div>


    <div style="margin-top: 7rem">
      <!--      <common-title en="ADMISSION RELATED" zh="入园相关"></common-title>-->
      <common-title :en="parkDependence.e_name" :zh="parkDependence.name"></common-title>
    </div>

    <div v-if="enterpriseList.length > 0" class="admission">
      <div class="admission-title">
        <div v-for="(item, index) in enterpriseList" :key="index" :class="[{active:currentIndex === index}]" class="title-item" @click="currentIndex=index">
          <div class="square"></div>
          <div class="wz">{{ item.name }}</div>
        </div>
        <div class="line"></div>
      </div>

      <div class="admission-main">
        <div class="main-cont reveal-top">
          <div @click="showCompanyPopup(item)" v-for="(item,index) in enterpriseList[currentIndex].children" :key="index" class="main-item">
            <span v-if="!item.resource">{{ item.name }}</span>
            <img v-if="item.resource" :src="item.resource" alt="">
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="line"></div>
      </div>

      <div class="admission-connect reveal-top">
        <div class="title">{{ parkDependence.communicate }}</div>
        <div class="cx-fex-l cx-fex-column cx-fex-itemsl">
          <div class="phone">{{ parkDependence.contact_us }}</div>
          <div class="address">{{ parkDependence.address }}</div>
        </div>
        <img :src="parkDependence.qrcode" alt="">
      </div>
    </div>

    <custom-popup v-model="popupVisible" :current-case="currentCase" popup-type="park"></custom-popup>

  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import scrollReveal from "scrollreveal";
import {getCompanyPark, getSettledEnterpriseList} from "@/request/api";
import {mapState} from "vuex";
import CustomPopup from "@/components/customPopup";

export default {
  components: {CustomPopup, CommonTitle},
  data() {
    return {
      parkData: null,
      parkDependence: null,
      scrollReveal: scrollReveal(),
      enterpriseList: [],
      currentIndex: 0,
      popupVisible: false,
      currentCase: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'fixedTextData']),
  },
  created() {
    this.getCompanyPark();
  },
  mounted() {

  },
  methods: {
    toParkDetailPage(id) {
      this.$router.push({
        path: '/parkDetail',
        query: {
          id: id
        }
      })
    },
    showCompanyPopup(item) {
      this.currentCase = item;
      this.popupVisible = true;
    },
    async getCompanyPark() {
      let res = await getCompanyPark({language_id: this.currentLanguage});
      this.parkData = res.data.data[0];
      this.parkDependence = res.data.dependence;
      await this.getSettledEnterpriseList();
      this.$nextTick(() => {
        this.setScrollRevealClass();
      })
    },
    async getSettledEnterpriseList() {
      let res = await getSettledEnterpriseList({language_id: this.currentLanguage});
      this.enterpriseList = res.data;
      console.log(res);
    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: '4rem',
        // 其他可用的动画效果
        opacity: 0.001,
        // easing: 'linear',
        scale: 0.9,
      })
    },
  }
}
</script>

<style lang="scss" scoped>

.park {
  width: 155.8rem;
  margin: 0 auto;
  overflow: hidden;

  .park-main {
    width: 100%;
    height: 72rem;
    display: flex;
    justify-content: space-between;

    .left {
      width: 100rem;
      padding: 3rem;
      background-image: linear-gradient(to bottom, #f6f8ff, #ffffff);
      box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.08);

      video {
        width: 100%;
        height: 42.1rem;
        background: #333333;
      }

      .left-title {
        margin-top: 3rem;
        font-size: 2.8rem;
        color: var(--default-color);
      }

      .left-desc {
        margin-top: 3rem;
        color: #666666;
        font-size: 1.6rem;
        line-height: 3.6rem;
      }
    }

    .right {
      width: 47.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .right-item {
        width: calc(100% - 6rem);
        height: 20rem;
        background-image: linear-gradient(to bottom, #f6f8ff, #ffffff);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.08);
        padding: 1rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .item-title {
          font-size: 1.8rem;
          font-weight: bold;
          color: #666666;
          padding-bottom: 1rem;
          border-bottom: .1rem solid #d1d1d1;
        }

        .list-cont {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-top: 1rem;

          .list-item {
            font-size: 1.6rem;
            color: #666666;
            line-height: 3rem;

            &.list-item-50 {
              width: calc(50% - 2rem);
              padding-right: 2rem;
            }

            &.list-item-100 {
              width: 100%;
            }
          }
        }

        .enter {
          font-size: 1.6rem;
          color: var(--default-color);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          i {
            font-size: 1.6rem;
            margin-top: .3rem;
          }
        }

        .bottom-line {
          position: absolute;
          bottom: 0;
          width: 50%;
          height: .5rem;
          background-color: var(--default-color);

        }
      }
    }
  }


  .admission {
    width: 100%;

    .admission-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .title-item {
        display: flex;
        align-items: center;

        .square {
          width: .5rem;
          height: 2.5rem;
          background-color: #C2C2C2;
        }

        .wz {
          font-size: 1.8rem;

          color: #C2C2C2;
          margin: 0 2rem 0 1rem;
        }

        &.active {
          .square {
            background-color: var(--default-color);
          }

          .wz {
            font-weight: bold;
            color: var(--default-color);
          }
        }
      }


      .line {
        width: 95.5rem;
        height: .5rem;
        background-image: linear-gradient(to right, #0967D1, #ffffff);
      }
    }

    .admission-main {
      padding: 7.5rem 7rem;

      .main-cont {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .main-item {
          width: 31.5rem;
          height: 10rem;
          border: .1rem solid var(--default-color);
          font-size: 1.6rem;
          color: #585858;
          text-align: center;
          //line-height: 10rem;
          margin: 2.5rem 1.8rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 80%;
            max-height: 80%;
          }

        }
      }

    }


    .bottom {
      display: flex;
      justify-content: flex-end;

      .line {
        width: 95.5rem;
        height: .5rem;
        background-image: linear-gradient(to left, #0967D1, #ffffff);
      }
    }

    .admission-connect {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--default-color);
      margin: 6rem 0;

      .title {
        font-size: 2.8rem;
        margin-right: 2rem;
      }

      .phone, .address {
        font-size: 1.6rem;
        line-height: 2;
      }

      img {
        width: 14.5rem;
        height: 14.5rem;
        margin-left: 5.5rem;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .park {
    width: 90%;
    margin: 0 auto;

    .park-main {
      width: 100%;
      height: auto;
      flex-wrap: wrap;

      .left {
        width: 100%;
        padding: 3rem;
        background-image: linear-gradient(to bottom, #f6f8ff, #ffffff);
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.08);

        .left-title {
          font-size: 4rem;
        }


      }

      .right {
        width: 100%;
        height: auto;

        .right-item {
          width: calc(100% - 6rem);
          height: auto;
          margin: 5rem auto 0;

          .item-title {
            font-size: 3.4rem;
            line-height: 5rem;
          }

          .list-cont {

            .list-item {
              font-size: 3rem;
              line-height: 6rem;

            }
          }

          .enter {
            font-size: 3rem;

            i {
              font-size: 3rem;
            }
          }

          .bottom-line {
            position: absolute;
            bottom: 0;
            width: 50%;
            height: .5rem;
            background-color: var(--default-color);

          }
        }
      }
    }


    .admission {
      width: 100%;

      .admission-title {
        flex-wrap: wrap;

        .title-item {

          .square {
            height: 4rem;
          }

          .wz {
            font-size: 4rem;
            margin: 0 4rem 0 2rem;
          }


        }


        .line {
          width: 100%;
          margin-top: 5rem;
          //display: none;
        }
      }

      .admission-main {
        padding: 7.5rem 0;

        .main-cont {

          .main-item {
            width: 100%;
            height: 20rem;
            font-size: 4rem;

            img {
              max-width: 80%;
              max-height: 80%;
            }

          }
        }

      }


      .admission-connect {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: var(--default-color);
        margin: 6rem 0;

        .title {
          font-size: 4rem;
          margin-right: 2rem;
        }

        .phone, .address {
          font-size: 2.6rem;
          line-height: 2;
        }

        img {
          width: 30rem;
          height: 30rem;
          margin-top: 5rem;
        }
      }
    }
  }
}
</style>
