<template>
  <div class="talent-development">
    <!--    <common-title en="TALENT DEVELOPMENT" zh="人才培养"></common-title>-->
    <common-title :en="cateData.children[0].e_name" :zh="cateData.children[0].name"></common-title>

    <div class="development-desc desc-font-size-m reveal-top">
      {{ cateData.children[0].introduction }}
    </div>

    <div class="development-list">
      <div v-for="(item,index) in list" :key="index" class="list-item reveal-top">
        <div :style="{backgroundImage:`url(${$tool.getImage(item.resource)})`}" class="image-cont"></div>
        <div class="right-cont">
          <div class="cx-fex">
            <div class="cx-fex cx-fex-column">
              <!--              <img alt="" class="logo" src="@/assets/images/title-logo.png">-->
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="ranking">{{ (index + 1) < 10 ? '0' : '' }}{{ index + 1 }}</div>
          </div>
          <div class="desc desc-font-size-m">{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonTitle from "@/components/commonTitle";
import scrollReveal from "scrollreveal";
import {mapState} from "vuex";
import {getTalentCaseList} from "@/request/api";

export default {
  props: {
    cateData: {
      type: Object
    }
  },
  components: {CommonTitle},
  computed: {
    ...mapState(['currentLanguage']),
  },
  data() {
    return {
      list: [],

      scrollReveal: scrollReveal()
    }
  },
  created() {
    this.getTalentCaseList()
  },
  mounted() {

  },
  methods: {
    async getTalentCaseList() {
      let res = await getTalentCaseList({language_id: this.currentLanguage, talent_id: this.cateData.id,talent_cate_id:this.cateData.id});
      this.list = res.data;
      this.$nextTick(() => {
        this.setScrollRevealClass();
      })
    },
    setScrollRevealClass() {
      this.scrollReveal.reveal('.reveal-top', {
        // 动画的时长
        duration: 1000,
        // 延迟时间
        delay: 200,
        // 动画开始的位置，'bottom', 'left', 'top', 'right'
        origin: 'bottom',
        // 回滚的时候是否再次触发动画
        reset: false,
        // 在移动端是否使用动画
        mobile: true,
        // 滚动的距离，单位可以用%，rem等
        distance: '4rem',
        // 其他可用的动画效果
        // opacity: 0.001,
        // easing: 'linear',
        // scale: 0.9,

      })
    },
  }
}
</script>

<style lang="scss" scoped>
.talent-development {
  width: 135rem;
  margin: 0 auto;

  .development-desc {
    color: #666666;
    font-size: 1.6rem;
    line-height: 2;
  }

  .development-list {
    padding-bottom: 5rem;
    padding-top: 10rem;

    .list-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13rem;

      .image-cont {
        width: 62.8rem;
        height: 43.8rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .right-cont {
        width: calc(100% - 73rem);

        .logo {
          width: 4rem;
          height: 2.3rem;
          margin-top: 6.4rem;
        }

        .title {
          font-size: 3.6rem;
          margin-top: 7.6rem;
          line-height: 1;
        }

        .desc {
          font-size: 1.6rem;
          line-height: 2;
          color: #666666;
          margin-top: 3.6rem;
        }

        .ranking {
          font-size: 13rem;
          font-weight: bold;
          /*形成空心部分⬇*/
          //font-family: element-icons;
          text-shadow: 0 0 2px black;
          color: white;
          line-height: 1;
        }

      }

      &:nth-of-type(even) {
        flex-direction: row-reverse;
      }
    }

  }
}

@media screen and (max-width: 1024px) {
  .talent-development {
    width: 90%;

    .development-list {
      .list-item {
        flex-direction: column;

        .image-cont {
          width: 100%;
        }

        .right-cont {
          width: 100%;
          margin-top: 5rem;
        }

        &:nth-of-type(even) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
