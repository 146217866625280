<template>
  <div v-if="detail">
    <common-title :en="detail.e_name" :zh="detail.name"></common-title>

    <div class="info-cont animated zoomInUp">
      <!--      <div class="title">{{ detail.title }}</div>-->
      <!--      <div class="desc desc-font-size-m">-->
      <!--        {{ detail.introduction }}-->
      <!--      </div>-->
      <div v-html="detail.description"></div>
      <div class="cx-fex-c" style="margin-top: 8rem">
        <video :src="detail.video" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
import {getCompany} from "@/request/api";
import {mapState} from "vuex";
import CommonTitle from "@/components/commonTitle";

export default {
  components: {CommonTitle},
  data() {
    return {
      detail: null,
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
  },
  created() {
    this.getCompany();
  },
  methods: {
    async getCompany() {
      let res = await getCompany({language_id: this.currentLanguage});
      this.detail = res.data;

    }
  }
}
</script>

<style lang="scss" scoped>
.title-cont {
  margin: 6rem;
  background-image: url("@/assets/images/title-bg-logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding-left: 12rem;

  .title {
    font-size: 4rem;
    color: var(--default-color);
  }

  .subTitle {
    font-size: 2.6rem;
    color: #999999;
    margin-left: 1rem;
  }
}

.info-cont {
  width: 134rem;
  margin: 0 auto;
  padding-bottom: 20rem;

  .title {
    font-size: 2.4rem;
    color: var(--default-color);
  }

  .desc {
    color: #5a5a5a;
    font-size: 1.6rem;
    line-height: 3.6rem;
    margin-top: 3rem;
  }

  video {
    width: 93rem;
    height: 48.5rem;
    background-color: #333333;
  }
}

@media screen and (max-width: 1024px) {
  .info-cont {
    width: 90%;

    .title {
      font-size: 4.8rem;
    }
  }
}

</style>
