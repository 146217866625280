<template>
  <div class="introduction">
    <custom-second-nav v-model="currentNavIndex" :list="cateData.children"></custom-second-nav>

    <div class="cx-fex-c">
      <common-title :en="cateData.children[currentNavIndex].e_name" :zh="cateData.children[currentNavIndex].name"></common-title>
    </div>

    <div class="introduction-cont">
      <div class="left-cont animated fadeInLeft">
        <div :style="{backgroundImage:`url(${$tool.getImage(cateData.children[currentNavIndex].resource)})`}" class="image-cont"></div>
        <div class="left-list">
          <div v-if="cateData.children[currentNavIndex].welfare_treatment" class="left-item">
            <div class="title">公司福利待遇</div>
            <div v-for="(item,index) in cateData.children[currentNavIndex].welfare_treatment.split('\n')" :key="index" class="desc desc-font-size-m">{{ item }}</div>
          </div>
          <div v-if="cateData.children[currentNavIndex].introduction" class="left-item">
            <div class="title">联系信息</div>
            <div class="desc desc-font-size-m">
              <div v-for="(item1,index1) in cateData.children[currentNavIndex].introduction.split('\n')" :key="index1">{{ item1 }}</div>
            </div>
          </div>
          <!--          <div v-if="cateData.children[currentNavIndex].branch_office" class="left-item">-->
          <!--            <div class="title">北京分公司</div>-->
          <!--            <div v-for="(item,index) in cateData.children[currentNavIndex].branch_office.split('\n')" :key="index" class="desc desc-font-size-m">{{ item }}</div>-->
          <!--          </div>-->
        </div>


      </div>

      <div class="right-cont animated fadeInRight">
        <custom-second-title en="JOIN US" zh="加入我们"></custom-second-title>

        <div ref="customScrollCont" class="custom-scroll-cont" @scroll="handleScroll">
          <div ref="scrollCont" class="scroll-cont">
            <div v-for="(item,index) in list" :key="item.id" ref="scrollItem" :class="[index === currentIndex ? 'active' : '']" class="scroll-item">
              <div class="title">{{ item.position }}</div>

              <div class="join-info">
                <div>部门：{{ item.department }}</div>
                <div>招聘人数：{{ item.nums }}</div>
                <div>工作地点：{{ item.working_place }}</div>
              </div>
              <div class="submit-title">工作内容</div>
              <div class="desc">
                <!--                {{ item.job_content }}-->
                <div v-for="(item1,index1) in item.job_content.split('\n')" :key="index1">{{ item1 }}</div>
              </div>
              <div class="submit-title">任职要求</div>
              <div class="desc">
                <div v-for="(item1,index1) in item.job_requirements.split('\n')" :key="index1">{{ item1 }}</div>
                <!--                {{ item.job_requirements }}-->
              </div>
              <div class="circular"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSecondNav from "@/components/customSecondNav";
import CustomSecondTitle from "@/components/customSecondTitle";
import CommonTitle from "@/components/commonTitle"
import {getTalentCaseList} from "@/request/api";
import {mapState} from "vuex";

export default {
  props: {
    cateData: {
      type: Object
    }
  },
  components: {CustomSecondTitle, CustomSecondNav, CommonTitle},
  data() {
    return {
      currentNavIndex: 0,
      navList: [],

      currentIndex: 0,
      domOffsetTopList: [],

      list: [],
    }
  },
  computed: {
    ...mapState(['currentLanguage']),
  },
  created() {
    this.getTalentCaseList();
  },
  mounted() {

  },
  watch: {
    currentNavIndex(e) {
      this.getTalentCaseList();
    }
  },
  methods: {
    async getTalentCaseList() {
      let data = {
        language_id: this.currentLanguage,
        talent_id: this.cateData.id,
        talent_cate_id: this.cateData.children[this.currentNavIndex].id
      }
      let res = await getTalentCaseList(data);
      this.list = res.data;
      this.$refs.customScrollCont.scrollTop = 0
      this.$nextTick(() => {
        this.getScrollItemOffsetTop();
      })
    },
    getScrollItemOffsetTop() {
      let arr = [];
      Array.from(this.$refs.scrollItem).forEach(item => {
        arr.push(item.offsetTop);
      })
      this.domOffsetTopList = arr;
    },
    handleScroll(ev) {
      let scrollTop = this.$refs.customScrollCont.scrollTop;
      for (let i = 0; i < this.domOffsetTopList.length; i++) {
        for (let j = 1; j < this.domOffsetTopList.length; j++) {
          if (scrollTop > this.domOffsetTopList[i] && scrollTop < this.domOffsetTopList[j]) {
            this.currentIndex = i + 1;
            break;
          }
        }
      }
      this.currentIndex = this.currentIndex < 0 ? 0 : this.currentIndex
      if (scrollTop === 0) {
        this.currentIndex = 0;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-cont {
  margin: 6rem;
  background-image: url("@/assets/images/title-bg-logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding-left: 12rem;

  .title {
    font-size: 4rem;
    text-align: center;
    color: var(--default-color);
  }

  .subTitle {
    font-size: 2.6rem;
    color: #999999;
    margin-left: 1rem;
  }
}

.introduction-cont {
  display: flex;
  justify-content: center;
  padding-bottom: 10rem;
  overflow: hidden;

  .left-cont {
    width: 64.3rem;

    .image-cont {
      width: 100%;
      height: 18.3rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .left-list {
      .left-item {
        .title {
          margin-top: 2rem;
          font-size: 1.8rem;
          color: var(--default-color);
          line-height: 3.6rem;
          font-weight: bold;
        }

        .desc {
          font-size: 1.6rem;
          color: #666666;
          line-height: 2;
        }
      }
    }
  }

  .right-cont {
    width: 72rem;
    padding-left: 12rem;
  }
}

@media screen and (max-width: 1024px) {
  .title-cont {
    padding-left: 0;
    margin: 10rem auto;

    .title {
      font-size: 6rem;
    }

    .subTitle {
      font-size: 4rem;
    }
  }

  .introduction-cont {
    width: 90%;
    margin: 0 auto;
    flex-direction: column;

    .left-cont {
      width: 100%;

      .left-list {
        .left-item {
          .title {
            font-size: 3rem;
            line-height: 2;
          }

          .desc {
            font-size: 2.4rem;
            line-height: 2;
          }
        }
      }
    }

    .right-cont {
      width: 100%;
      padding-left: 0;
      margin: 5rem auto;
    }
  }
}
</style>
